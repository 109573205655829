import { TradeStatus } from 'constants/general';
import { InitiatorTypeEnum } from 'models/trade';


export const SCOPE_TRDADES = 'Trades';
export const SCOPE_TRDADES_COLUMNS = `${SCOPE_TRDADES}.columns`;


export const uk = {
  title: 'Мої торги',
  trade: 'Торг',
  columns: {
    trade: 'Торг',
    culture: 'Культура',
    type: 'Тип',
    info: 'Інформація',
    origin: 'Походження',
    // companyInfo: 'Країна',
    // openType: 'Ініціатор',
    status: 'Статус',
    validUntil: 'Діє до',
    unread: '',
  },
  initiatorType: {
    [InitiatorTypeEnum.got]: 'Отриман',
    [InitiatorTypeEnum.opened]: 'Відкрит',
  },
  statuses: {
    [TradeStatus.closed]: 'Закрит',
    [TradeStatus.confirmed]: 'Підтверджен',
  },
  filters: {
    all: 'Все',
    open: 'Відкриті',
    received: 'Отримані',
    confirmed: 'Підтверджені',
    closed: 'Закриті',
    allCompanyTrades: 'Всією Компанії',
  },
  tooltips: {
    [InitiatorTypeEnum.got]: 'Отриманий торг',
    [InitiatorTypeEnum.opened]: 'Створений торг',
  },
};

export const en: typeof uk = {
  title: 'My trades',
  trade: 'Trade',
  columns: {
    trade: 'Trade',
    culture: 'Culture',
    type: 'Type',
    info: 'Information',
    origin: 'Origin',
    // companyInfo: 'Country',
    // openType: 'Initiator',
    status: 'Status',
    validUntil: 'Valid until',
    unread: '',
  },
  initiatorType: {
    [InitiatorTypeEnum.got]: 'Received',
    [InitiatorTypeEnum.opened]: 'Opened',
  },
  statuses: {
    [TradeStatus.closed]: 'Closed',
    [TradeStatus.confirmed]: 'Confirmed',
  },
  filters: {
    all: 'All',
    open: 'Opened',
    received: 'Received',
    confirmed: 'Confirmed',
    closed: 'Closed',
    allCompanyTrades: 'All Company',
  },
  tooltips: {
    [InitiatorTypeEnum.got]: 'Received trade',
    [InitiatorTypeEnum.opened]: 'Created trade',
  },
};

export const ru: typeof uk = {
  title: 'Мои торги',
  trade: 'Торг',
  columns: {
    trade: 'Торг',
    culture: 'Культура',
    type: 'Тип',
    info: 'Информация',
    origin: 'Происхождение',
    // companyInfo: 'Страна',
    // openType: 'Инициатор',
    status: 'Статус',
    validUntil: 'Действует до',
    unread: '',
  },
  initiatorType: {
    [InitiatorTypeEnum.got]: 'Получен',
    [InitiatorTypeEnum.opened]: 'Открыт',
  },
  statuses: {
    [TradeStatus.closed]: 'Закрыт',
    [TradeStatus.confirmed]: 'Подтвержден',
  },
  filters: {
    all: 'Все',
    open: 'Открытые',
    received: 'Полученные',
    confirmed: 'Подтвержденные',
    closed: 'Закрытые',
    allCompanyTrades: 'Всей Компании',
  },
  tooltips: {
    [InitiatorTypeEnum.got]: 'Полученный торг',
    [InitiatorTypeEnum.opened]: 'Созданный торг',
  },
};
