// import { combineReducers } from 'redux';
import { combineReducers } from '@reduxjs/toolkit';
import { createDialogsReducer } from './dialogsReducer';
import activePageDataReducer from './activePageDataReducer';
import addresses from './slices/addresses.slice';
import allReducer from './allReducer';
import culturesReducer from './culturesReducer';
import favorites from './favoritesReducer';
import filtersReducer from './filtersReducer';
import infoPopupReducer from './infoPopup';
import inviteReducer from './inviteReducer';
import invitesReducer from './invitesReducer';
import lots from './lotsReducer';
import markets from './slices/markets.slice';
import myLots from './myLotsReducer';
import notificationsReducer from './notificationsReducer';
import oneOfferReducer from './oneOfferReducer';
import oneRequestReducer from './oneRequestReducer';
import oneTradeReducer from './oneTradeReducer';
import trades from './slices/trades.slice';
import tradesInfoBadges from './slices/tradesInfoBadges.slice';
import dashboard from './dashboardReducer';
import filtersData from './slices/filtersData.slice';
import userDashboard from './slices/userDashboard.slice';
import profile from './profile';

export default combineReducers({
  ...createDialogsReducer(),
  activePage: activePageDataReducer,
  addresses,
  all: allReducer,
  cultures: culturesReducer,
  dashboard,
  favorites,
  filters: filtersReducer,
  filtersData,
  infoPopup: infoPopupReducer,
  invite: inviteReducer,
  invites: invitesReducer,
  lots,
  markets,
  myLots,
  notifications: notificationsReducer,
  oneOffer: oneOfferReducer,
  oneRequest: oneRequestReducer,
  oneTrade: oneTradeReducer,
  trades,
  tradesInfoBadges,
  userDashboard,
  profile,
});
