import { IOffer } from 'reducers/oneOfferReducer';
import { IRequest } from 'reducers/oneRequestReducer';
import { LotType } from './general';


export const ROUTES = {
  activation: '/activation/:activation_token/',
  chat: '/chat/',
  categories: '/categories/:categoryChurl/',
  cultures: '/cultures/:cultureChurl/',
  lot: `/proposals/:type(${Object.keys(LotType).join('|')})/:id(\\d+)/`,
  editLot: `/proposals/:type(${Object.keys(LotType).join('|')})/:id(\\d+)/edit/`,
  createLot: `/proposals/:type(${Object.keys(LotType).join('|')})/create/`,
  tradeLot: `/proposals/:type(${Object.keys(LotType).join('|')})/:id(\\d+)/trade/`,
  cloneLot: `/proposals/:type(${Object.keys(LotType).join('|')})/:id(\\d+)/clone/`,
  createFromFavorites: '/proposals/offer/create/',
  createFromMarket: '/proposals/offer/create/',
  createFromMyProposals: '/proposals/offer/create/',
  createFromRequests: '/proposals/offer/create/',
  createFromTrades: '/proposals/offer/create/',
  dashboard: '/dashboard/',
  emailConfirmationInfo: '/email-confirmation-info/',
  emptyPage: '/market/empty-page/',
  favorites: '/favorites/',
  home: '/',
  landingPageLayout: {
    root: '/',
    about: '/about/',
    contacts: '/contacts/',
    farmers: '/farmers/',
    brokers: '/brokers/',
    customers: '/buyers/',
  },
  howItWorks: '/how-it-works/',
  logout: '/logout/',
  invite: '/invite/:token/',
  market: '/market/',
  marketCategory: '/market/:categoryChurl/',
  myProposals: '/my-proposals/',
  newPassword: '/new-password/:token/',
  notFound: '/404/',
  notifications: '/notifications/',
  offerPage: '/proposals/offer/',
  offerPageMyProposals: '/proposals/offer/',
  privacyPolicy: '/privacy-policy/',
  proposals: '/proposals/',
  publicAgreement: '/public-agreement/',
  quickAuth: '/quick-login/:otp_token/',
  registration: {
    root: '/registration/',
    company: '/registration/company/',
  },
  previewPage: `/proposals/:type(${LotType.request}|${LotType.offer})/:id(\\d+)/`,
  requestPage: '/proposals/request/',
  requestPageMyProposals: '/proposals/request/',
  resetPassword: '/reset-password/',
  settings: {
    root: '/settings/',
    company: '/settings/company/',
    subscription: '/settings/subscription/',
    user: '/settings/user/',
    users: '/settings/users/',
    invites: '/settings/invites/',
    addresses: {
      root: '/settings/addresses/',
      archive: '/settings/addresses/archive/',
      show: '/settings/addresses/:id(\\d+)/',
      edit: '/settings/addresses/:id(\\d+)/edit/',
      clone: '/settings/addresses/:id(\\d+)/clone/',
      create: '/settings/addresses/create/',
    },
  },
  signIn: '/sign-in/',
  signUp: '/sign-up/',
  trades: '/trades/',
  landingPages: '/lp/:cultureId/:countryId/',
} as const;


export type FlattenObjectKeys<
  T extends Record<string, unknown>,
  Value = T[keyof T]
> = Value extends string
  ? Value
  : Value extends Record<string, unknown>
    ? FlattenObjectKeys<Value>
    : never;


export const GROUP_ROUTES = {
  authLayout: [ROUTES.signIn, ROUTES.signUp, ROUTES.resetPassword, ROUTES.newPassword],
  registrationLayout: [ROUTES.registration.root, ROUTES.registration.company],
};

export class BuilderLinkTo {
  static lot(lot: { id: number, type: string }): string {
    if (lot?.type === 'offer') return this.offer(lot);
    if (lot?.type === 'request') return this.request(lot);
    return ROUTES.market;
  }
  static offer(offer: { id: number }): string {
    if (!offer) return ROUTES.market;
    return `${ROUTES.offerPage}${offer.id}/`;
  }
  static request(request: { id: number }): string {
    if (!request) return ROUTES.market;
    return `${ROUTES.requestPage}${request.id}/`;
  }
  static trade(
    instance: IOffer | IRequest,
  ): string {
    if (instance.trade?.id) {
      const type: LotType = instance.is_my || instance.is_my_company ? instance.type : instance.type === 'offer' ? LotType.request : LotType.offer;
      const tradeKey = `${type}_id` as keyof typeof instance.trade;
      return `${ROUTES.chat}?tradeId=${instance.trade.id}&type=${type}&id=${instance.trade[tradeKey]}`;
    }
    return '';
  }
}
