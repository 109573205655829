export const uk = {
  Message: {
    buttons: {
      offer_price: 'Запропонувати ціну',
      accept_offer: 'Прийняти пропозицію',
      leave_trade: 'Вийти з торгів',
    },
    types: {
      confirmation: 'Підтвердження',
      trade_closed: '',
      bid_changed: 'Зміна ставки',
      proposal_changed: 'Зміна пропозиції',
      is_allowed_action: '',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Нічого відправляти',
      errorSend: 'Не вдалося відправити повідомлення',
      exceedLength: 'Перевищено ліміт сімвлов ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Відправити повідомлення',
    },
  },
  TradeList: {
    tooltips: {
      lastMessageTime: 'Останнє повідомлення',
      messagesCount: 'Загальна кількість повідомлень',
      unreadCount: 'Непрочитані повідомлення',
    },
  },
};


export const ru: typeof uk = {
  Message: {
    buttons: {
      offer_price: 'Предложить цену',
      accept_offer: 'Принять предложение',
      leave_trade: 'Выйти из торгов',
    },
    types: {
      confirmation: 'Подтверждение',
      trade_closed: '',
      bid_changed: 'Изменение ставки',
      proposal_changed: 'Изменение предложения',
      is_allowed_action: '',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Нечего отправлять',
      errorSend: 'Не удалось отправить сообщение',
      exceedLength: 'Превышен лимит симвлов ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Отправить сообщение',
    },
  },
  TradeList: {
    tooltips: {
      lastMessageTime: 'Последнее сообщение',
      messagesCount: 'Общее количество сообщений',
      unreadCount: 'Непрочитанные сообщения',
    },
  },
};

export const en: typeof uk = {
  Message: {
    buttons: {
      offer_price: 'Offer price',
      accept_offer: 'Accept offer',
      leave_trade: 'Leave trade',
    },
    types: {
      confirmation: 'confirmation',
      trade_closed: '',
      bid_changed: 'bid_changed',
      proposal_changed: 'proposal_changed',
      is_allowed_action: '',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Nothing to send',
      errorSend: 'Failed to send message',
      exceedLength: 'Exceeded character limit ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Send message',
    },
  },
  TradeList: {
    tooltips: {
      lastMessageTime: 'Last message',
      messagesCount: 'Total number of messages',
      unreadCount: 'Unread messages',
    },
  },
};
