import moment from 'moment';
import { FORMAT_DATES } from 'constants/general';

export const SET_ACTIVE_PAGE_DATA = 'SET_ACTIVE_PAGE_DATA';

interface Params {
  title?: string;
  backButton?: boolean;
  type?: string | null;
  numberRequest?: number | string;
  date?: string;
  to?: string;
  hideSideBar?: boolean;
  typeOveralls?: string | null;
  h1?: boolean;
}

export const setActivePageData = ({
  title = '',
  backButton = false,
  type = null,
  numberRequest = '',
  date = '',
  to = '',
  hideSideBar = false,
  typeOveralls = null,
  h1 = false,
}: Params) => ({
  type: SET_ACTIVE_PAGE_DATA,
  payload: {
    title,
    backButton,
    type,
    numberRequest,
    date: date && moment(date).format(FORMAT_DATES.main),
    to,
    hideSideBar,
    typeOveralls,
    h1,
  },
});
