import React from 'react';
import './styles.scss';

const SvgIconContainer = ({ className = '', classes = '', mirrow = false, children }) => (
  <div className={`
    svg-icon-container
    ${mirrow ? 'mirrow' : ''}
    ${classes}
    ${className}
  `}>
    {children}
  </div>
);

export default SvgIconContainer;
