import { createSlice } from '@reduxjs/toolkit';
import { LotType } from 'constants/general';
import { TodoAny } from 'core/interfaces/common.interfaces';
import { ParsedQuery } from 'query-string';
import Notificate from 'services/notificate.service';
import { RootState } from 'store/store.types';

const name = 'filtersData';
const IdsFields = [
  'basis',
  'categories',
  'companies',
  'countries',
  'cultures',
  'origins',
  'packaging_options',
  'port_options',
] as const;
const StringFields = ['shipping_date_from', 'shipping_date_to', 'search'] as const;

type IInitialState =
  Record<typeof IdsFields[number], number[]> &
  Partial<
    Record<typeof StringFields[number], string>
  > &
  { type?: LotType };

const initialState: IInitialState = {
  basis: [],
  categories: [],
  companies: [],
  countries: [],
  cultures: [],
  origins: [],
  port_options: [],
  packaging_options: [],
  shipping_date_from: '',
  shipping_date_to: '',
  search: undefined,
};

type ActionKeyValue = {
  payload: {
    key: keyof IInitialState,
    value: IInitialState[keyof IInitialState],
  },
}

export const {
  reducer,
  actions: {
    initFilterData,
    updateFilter,
    toggleFilterItem,
    resetFilter,
    resetFilterData,
  },
} = createSlice({
  name,
  initialState,
  reducers: {
    initFilterData: (state, action: { payload: ParsedQuery }) => _init(action.payload || {}),
    updateFilter: (state, action: ActionKeyValue) => {
      Notificate.standard(); state[action.payload.key] = action.payload.value as TodoAny;
    },
    toggleFilterItem: (state, { payload: { key, value } }: ActionKeyValue) => {
      const values = state[key];
      const strValue = value?.toString();
      if (Array.isArray(values)) {
        const num = +(strValue || 0);
        values.includes(num) ? state[key] = values.filter(item => item !== num) as TodoAny : values.push(num) as TodoAny;
      } else {
        state[key] = values == strValue ? null : strValue as TodoAny;
      }
      Notificate.standard();
    },
    resetFilter: (state, action: { payload: { key: keyof IInitialState } }) => {
      Notificate.reset();
      state[action.payload.key] = initialState[action.payload.key] as TodoAny;
    },
    resetFilterData: () => { Notificate.reset(); return initialState; },
  },
});

export default reducer;


function _init(query: ParsedQuery): IInitialState {
  const [dateStart, dateEnd] = query.date_range?.toString()?.split('_') || ['', ''];
  const state = {
    type: query.type,
    shipping_date_from: dateStart,
    shipping_date_to: dateEnd,
  } as IInitialState;
  IdsFields.forEach((key) => {
    state[key] = [query[key]].flat().filter(Boolean).map(id => parseInt(`${id}`));
  });
  return state;
}

export const filtersData = (state: RootState) => state[name];
export const filtersDataByKey = (key: keyof IInitialState) => (state: RootState) => state[name][key];
