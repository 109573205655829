import React from 'react';
import { CircularProgress, Box, ButtonProps } from '@material-ui/core';
import { SaveButtonNotStrict } from 'components/#CustomComponents';
import './styles.scss';

type Props = {
  rootClassName?: string;
  loading?: boolean;
  justifyContent?: string;
  show?: boolean;
} & ButtonProps;


export const LoadingButton: React.FC<Props> = ({ show = true, justifyContent = 'center', rootClassName = '', loading = false, children, ...props }) => {
  if (!show) {
    return null;
  }
  return (
    <Box
      display="flex"
      justifyContent={justifyContent}
      alignItems="center"
      position="relative"
      className={`loading-button ${rootClassName}`}
    >
      <SaveButtonNotStrict disabled={loading} {...props}>
        {children}
      </SaveButtonNotStrict>
      {loading && <CircularProgress className="cirular-progress" />}
    </Box>
  );
};
