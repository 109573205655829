export const Basis = {
  api: '/basis/',
  name: 'basis',
  plural: 'basis',
};

export const Category = {
  api: '/categories/',
  name: 'category',
  plural: 'categories',
};

export const Company = {
  api: '/companies/',
  name: 'company',
  plural: 'companies',
};

export const Country = {
  api: '/countries/',
  name: 'country',
  plural: 'countries',
};

export const Culture = {
  api: '/cultures/simple/',
  name: 'culture',
  plural: 'cultures',
};

export const Currency = {
  api: '/currencies/',
  name: 'currency',
  plural: 'currencies',
};

export const DocumentsOption = {
  api: '/documents_options/',
  name: 'documents_option',
  plural: 'documents_options',
};

export const Market = {
  api: '/markets/',
  name: 'market',
  plural: 'markets',
};

export const Origin = {
  api: '/countries/',
  name: 'origin',
  plural: 'origins',
};

export const PackagingOption = {
  api: '/packaging_options/',
  name: 'packaging_option',
  plural: 'packaging_options',
};

export const Parameter = {
  api: '/parameters/',
  name: 'parameter',
  plural: 'parameters',
};

export const PaymentsOption = {
  api: '/payments_options/',
  name: 'payments_option',
  plural: 'payments_options',
};

export const PortsOption = {
  api: '/port_options/',
  name: 'ports_option',
  plural: 'port_options',
};

export const ShippingLine = {
  api: '/shipping_lines/',
  name: 'shipping_line',
  plural: 'shipping_lines',
};

export const ShippingOption = {
  api: '/shipping_options/',
  name: 'shipping_option',
  plural: 'shipping_options',
};

export const LIST = [
  Basis,
  Category, Company, Country, Culture, Currency,
  DocumentsOption,
  Market,
  // Origin,
  PackagingOption, Parameter, PaymentsOption, PortsOption,
  ShippingLine, ShippingOption,
];

export default {
  Basis,
  Category,
  Company,
  Country,
  Culture,
  Currency,
  DocumentsOption,
  Market,
  Origin,
  PackagingOption,
  Parameter,
  PaymentsOption,
  PortsOption,
  ShippingLine,
  ShippingOption,
};
