import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeNotificationsChatMessagesCount } from 'actions/notificationsActions';
import { API, API_ROUTES } from 'modules/Api';
import { FactoryBuilder, factoryInitState } from './helpers';
import { IInitState } from './interfaces';

export { useTsSelector, useTsDispatch } from 'store/store.types';


export interface ITradesInfoBadges {
  open_count: number,
  received_count: number,
  confirmed_count: number,
  unread_trade_message: number,
}


const initialData: ITradesInfoBadges = {
  open_count: 0,
  received_count: 0,
  confirmed_count: 0,
  unread_trade_message: 0,
};

const initialState: IInitState<ITradesInfoBadges> = factoryInitState(initialData);



export const NAME = 'tradesInfoBadges';
const scope = `${NAME}`;

export const getTradesInfoBadges  = createAsyncThunk(
  `${scope}/get`,
  async (_: void, { dispatch }): Promise<ITradesInfoBadges> => {
    return API.get<ITradesInfoBadges>(API_ROUTES.trades.infoBadges).then(res => {
      dispatch(changeNotificationsChatMessagesCount({ absolute: res.data.unread_trade_message }));
      return res.data;
    });
  },
);


export const {
  reducer,
  // actions: { },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
  },
  extraReducers: builder => new FactoryBuilder(builder)
    .buildAction(getTradesInfoBadges),
});

export default reducer;
