import i18n from 'i18n';
import { TOptions } from 'i18next';
import { InfoPopupEnum } from '../hooks/useInfoPopup';

export const SET_INFO_POPUP = 'SET_INFO_POPUP';
export const SHOW_INFO_POPUP = 'SHOW_INFO_POPUP';

export const setInfoPopup = (type: InfoPopupEnum, message: string, to?: string) => ({
  type: SET_INFO_POPUP,
  payload: { type, message, to },
});

export const showInfoPopup = (show: boolean) => ({
  type: SHOW_INFO_POPUP,
  payload: { show },
});

const send = (type: InfoPopupEnum, message: string, opt?: TOptions) => {
  return setInfoPopup(type, i18n.t(message, opt));
};

export const infoPopupActions = {
  send,
  error: (message: string, opt?: TOptions) => send(InfoPopupEnum.error, message, opt),
  black: (message: string, opt?: TOptions) => send(InfoPopupEnum.black, message, opt),
  info: (message: string, opt?: TOptions) => send(InfoPopupEnum.info, message, opt),
  success: (message: string, opt?: TOptions) => send(InfoPopupEnum.success, message, opt),
  link: (message: string, to: string, opt?: TOptions) => setInfoPopup(InfoPopupEnum.link, i18n.t(message, opt), to),
  apiErrors: (error?: { response?: { data?: {}, status?: number }}) => {
    if (!error?.response?.data || error.response.status === 500) return send(InfoPopupEnum.error, 'somethingsWrong');
    return send(InfoPopupEnum.error, convertApiFormErrorsToString(error.response.data));
  },
};


function convertApiFormErrorsToString(errors: {}): string {
  if (typeof errors === 'string') {
    return errors;
  }
  return Object.entries(errors)
    .map(([field, value]) => `${field}: ${value}`)
    .join('\n');
}
