import React from 'react';
import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useT, useInfoPopup } from 'hooks';
import { useTsSelector, isAuthSelector } from 'reducers/profile/user.slice';
import { setToken, deleteToken } from 'actions/inviteActions';
import { Alert } from 'components/Alert';
import { DialogConfirm } from 'components/DialogConfirm';
import LoaderForData from 'components/_LoaderForData';
import { API } from 'modules/Api';
import { ROUTES } from 'constants/routes';

const scope = 'Invite';

export default function Invite() {
  const _t = useT(scope);
  const history = useHistory();
  const { token } = useParams();
  const { isAuth } = useTsSelector(isAuthSelector);
  const invite = useTsSelector(({ invite }) => invite);
  const dispatch = useDispatch();
  const infoPopup = useInfoPopup(scope);
  const [acceptLoading, setAcceptLoading] = useState(false);

  useEffect(() => { dispatch(setToken(token)); }, [token, dispatch]);

  const onClose = () => {
    dispatch(deleteToken());
    history.push(ROUTES.market);
  };

  const show = invite.company || !invite.error;
  const company_name = invite.company || '';

  const onConfirm = () => {
    if (invite.error) return onClose();
    if (!isAuth) {
      infoPopup.info('messages.info_next_for_unauth', { email: invite.email });
      return history.push(ROUTES.signUp);
    }
    setAcceptLoading(true);
    API.post(`/companies/invites/${token}/`)
      .then(() => {
        infoPopup.info('messages.info_accept_to_company', { company_name });
        onClose();
      })
      .catch(infoPopup.apiErrors)
      .finally(() => setAcceptLoading(false));
  };

  return (
    <LoaderForData show={invite.loading}>
      <Alert show={invite.error} onClose={onClose}>
        <h3>{_t('text.alert_error')}</h3>
        <br />
        {invite.error && Object.values(invite.error).length > 0 ?
          Object.values(invite.error).map((error, index) =>
            <Box key={index}>{JSON.stringify(error)}</Box>,
          )
          :
          <Box>{JSON.stringify(invite.error)}</Box>
        }
      </Alert>
      <DialogConfirm show={show} loading={acceptLoading} onConfirm={onConfirm} onClose={onClose}>
        {_t('text.confirm', { company_name } )}
      </DialogConfirm>
    </LoaderForData>
  );
}
