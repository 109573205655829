export const SCOPE_LINK_IN_NEW = 'LinkInNew';

export const uk = {
  defaultTooltipText: 'Відкрити в новому вікні',
};

export const en: typeof uk = {
  defaultTooltipText: 'Open in new window',
};

export const ru: typeof uk = {
  defaultTooltipText: 'Открыть в новом окне',
};
