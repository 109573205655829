export const uk = {
  month_one: 'місяць',
  month_few: 'місяця',
  month_many: 'місяців',
  user_one: 'пользователь',
  user_few: 'пользователя',
  user_many: 'пользователей',
  requests_one: '{{count}} запит',
  requests_few: '{{count}} запита',
  requests_many: '{{count}} запитів',
  offers_one: '{{count}} лот',
  offers_few: '{{count}} лота',
  offers_many: '{{count}} лотів',
  lot_one: '{{count}} заявка',
  lot_few: '{{count}} заявки',
  lot_many: '{{count}} заявок',
  day_one: '{{count}} день',
  day_few: '{{count}} дні',
  day_many: '{{count}} днів',
  volumes: '{{count}} т',
  daysLeft_one: 'Залишився день',
  daysLeft_few: 'Залишилося {{count}} дня',
  daysLeft_many: 'Залишилося {{count}} днів',
  trade_one: '1 Торг',
  trade_few: '{{count}} Торгу',
  trade_many: '{{count}} Торгів',

  somethingsWrong: 'Помилка при підключенні до сервера. Спробуйте пізніше або зверніться до Підтримки',
  archive: 'Архівувати',
  open: 'Открити',
  clone: 'Клонувати',
  close: 'Закрити',
  clear: 'Очистити',
  save: 'Зберегти',
  noOptions: 'Немає варіантів',
  choose: 'Вибрати',
  cancel: 'Відміна',
  confirm: 'Підтвердити',
  back: 'Назад',
  lot: 'Заявка',
  ok: 'Добре',
  error: 'Помилка',
  created: 'Створено',
  create: 'Створити',
  remove: 'Видалити',
  saved: 'Збережено',
  emptyList: 'Список порожній',
  my: 'моя',
  '№': '№',
  '#': '№',
  'in': 'в',
  't': 'т',
};

export const en = {
  month_one: 'month',
  month_other: 'months',
  user_one: 'user',
  user_other: 'users',
  requests_one: '{{count}} bid',
  requests_other: '{{count}} bids',
  offers_one: '{{count}} offer',
  offers_other: '{{count}} offers',
  volumes: '{{count}} t',
  daysLeft_one: 'Day left',
  daysLeft_other: '{{count}} days left',
  trade_one: '1 Trade',
  trade_other: '{{count}} Trades',
  lot_one: '{{count}} lot',
  lot_other: '{{count}} lots',
  day_one: '{{count}} day',
  day_other: '{{count}} days',

  somethingsWrong: 'An error occurred while connecting to the server. Try again later or contact Support',
  archive: 'Archive',
  open: 'Open',
  clone: 'Clone',
  close: 'Close',
  clear: 'Clear',
  save: 'Save',
  noOptions: 'No options',
  choose: 'Choose',
  cancel: 'Cancel',
  confirm: 'Confirm',
  back: 'Back',
  lot: 'Request',
  ok: 'OK',
  error: 'Error',
  create: 'Create',
  created: 'Created',
  remove: 'Delete',
  saved: 'Saved',
  emptyList: 'List is empty',
  my: 'my',
  '№': '#',
  '#': '#',
  'in': 'in',
  't': 't',
};

export const ru: typeof uk = {
  month_one: 'месяц',
  month_few: 'месяца',
  month_many: 'месяцев',
  user_one: 'пользователь',
  user_few: 'пользователя',
  user_many: 'пользователей',
  requests_one: '{{count}} запрос',
  requests_few: '{{count}} запроса',
  requests_many: '{{count}} запросов',
  offers_one: '{{count}} лот',
  offers_few: '{{count}} лота',
  offers_many: '{{count}} лотов',
  volumes: '{{count}} т',
  daysLeft_one: 'Остался день',
  daysLeft_few: 'Осталось {{count}} дня',
  daysLeft_many: 'Осталось {{count}} дней',
  trade_one: '1 Торг',
  trade_few: '{{count}} Торга',
  trade_many: '{{count}} Торгов',
  lot_one: '{{count}} заявка',
  lot_few: '{{count}} заявки',
  lot_many: '{{count}} заявок',
  day_one: '{{count}} день',
  day_few: '{{count}} дня',
  day_many: '{{count}} дней',

  somethingsWrong: 'Ошибка при подключении к серверу. Попробуйте позже или обратитесь к Поддержке',
  archive: 'Архивировать',
  open: 'Открыть',
  clone: 'Клонировать',
  close: 'Закрыть',
  clear: 'Очистить',
  save: 'Сохранить',
  noOptions: 'Нет вариантов',
  choose: 'Выбрать',
  cancel: 'Отмена',
  confirm: 'Подтвердить',
  back: 'Назад',
  ok: 'ОК',
  error: 'Ошибка',
  create: 'Создать',
  created: 'Создано',
  lot: 'Заявка',
  remove: 'Удалить',
  saved: 'Сохранено',
  emptyList: 'Список пуст',
  my: 'моя',
  '№': '№',
  '#': '№',
  'in': 'в',
  't': 'т',
};
