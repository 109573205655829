import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AnyTODO } from 'core/interfaces/AnyTODO.type';
import { API, API_ROUTES } from 'modules/Api';
import { RootState } from 'store/store.types';
import { factoryInitListState, FactoryListBuilder } from './helpers';
import { IApiListResponse, IInitListState } from './interfaces';
import { LotType, TradeStatus } from 'constants/general';
import { toApiQuery } from 'api/utils';
import { IOffer } from 'reducers/oneOfferReducer';
import { IRequest } from 'reducers/oneRequestReducer';

export { useTsSelector } from 'store/store.types';

export interface ITrade {
  id: number;
  created_at: string;
  status: TradeStatus;
  initiator: LotType;
  buyer_confirmation: boolean;
  seller_confirmation: boolean;
  price: number;
  volume: number;
  currency_id: number;
  valid_until: string;
  basis_id: number;
  shipping_id: number;
  packaging_id: number;
  [LotType.offer]?: IOffer;
  [LotType.request]?: IRequest;
  messages_count: number;
  unread_messages_count: number;
  last_message_time: string;
  unread_messages: number;
  is_my: boolean;
}


const initialState: IInitListState<ITrade> = factoryInitListState<ITrade>();


export const NAME = 'trades';
const scope = `${NAME}`;

export const getList  = createAsyncThunk(
  `${scope}/getList`,
  async (data: { params: Record<string, AnyTODO>, append: boolean }, thunkApi): Promise<IApiListResponse<ITrade> | null> => {
    const next: string | null | undefined = (thunkApi.getState() as RootState).trades.next;
    const apiUrl = data.append && next ? next : `${API_ROUTES.trades.root}?${toApiQuery(data.params)}`;
    return API.get<IApiListResponse<ITrade>>(apiUrl).then(res => res.data);
  },
);


export const {
  reducer,
  // actions: { },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
  },
  extraReducers: builder => new FactoryListBuilder<ITrade, IInitListState<ITrade>>(builder).buildListAction(getList),
});

export default reducer;
