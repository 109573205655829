export const uk = {
  empty_list: 'Список порожній',
  titles: {
    list: 'Список Запрошень',
  },
  text: {
    list_info: 'Тут можна подивитися історію Запрошень відправлених на Вашу адресу',
    alert_error: 'На жаль, щось з Запрошенням пішло не так:',
    confirm: 'Ви були запрошені в компанію "{{company_name}}". Будь ласка, підтвердіть приєднання до зазначеної компанії',
  },
  tables: {
    list: 'Історія Запрошень',
  },
  columns: {
    name: 'Ім\'я',
    company: 'Компанія',
    status: 'Статус',
    expiring: 'Діє',
    actions: 'Дії',
  },
  statuses: {
    created: 'створено',
    sent: 'відіслано',
    accepted: 'прийнято',
    rejected: 'відмова',
    closed: 'закрито',
    expired: 'прострочено',
  },
  tooltips: {
    close: 'Відкликати запрошення',
    accept: 'Прийняти запрошення',
    reject: 'Відмовитися від запрошення',
  },
  messages: {
    submit_success_accepted: 'Пропозиція успішно прийнято',
    submit_success_rejected: 'Пропозиція успішно відхилено',
    submit_success_closed: 'Запрошення було успішно відкликано',
    info_next_for_unauth: 'Щоб прийняти запрошення, необхідно авторизувати або створити відповідний обліковий запис ({{email}})',
    info_accept_to_company: 'Вітаємо з успішним вступом до лав Компанії "{{company_name}}"',
  },
  confirms: {
    message_accepted: 'Ви впевнені що хочете прийняти пропозицію "{{name}}"',
    message_rejected: 'Ви впевнені що хочете відхилити пропозицію "{{name}}"',
    message_closed: 'Ви впевнені що хочете відкликати запрошення для {{name}}?',
  },
};

export const ru = {
  empty_list: 'Список пуст',
  titles: {
    list: 'Список Приглашений',
  },
  text: {
    list_info: 'Здесь можно посмотреть историю Приглашений отправленных в Ваш адрес',
    alert_error: 'К сожалению, что-то с Приглашением пошло не так:',
    confirm: 'Вы были приглашены в компанию "{{company_name}}". Пожалуйста, подтвердите присоединение к указанной компании',
  },
  tables: {
    list: 'История Приглашений',
  },
  columns: {
    name: 'Имя',
    company: 'Компания',
    status: 'Статус',
    expiring: 'Действует',
    actions: 'Действия',
  },
  statuses: {
    created: 'создано',
    sent: 'отослано',
    accepted: 'принято',
    rejected: 'отказ',
    closed: 'закрыто',
    expired: 'просрочено',
  },
  tooltips: {
    close: 'Отозвать приглашение',
    accept: 'Принять приглашение',
    reject: 'Отказаться от приглашения',
  },
  messages: {
    submit_success_accepted: 'Предложение успешно принято',
    submit_success_rejected: 'Предложение успешно отклонено',
    submit_success_closed: 'Приглашение было успешно отозвано',
    info_next_for_unauth: 'Чтобы принять приглашение, необходимо авторизировать или создать соответсвующий аккаунт ({{email}})',
    info_accept_to_company: 'Поздравляем с успешным вступлением в ряды Компании "{{company_name}}"',
  },
  confirms: {
    message_accepted: 'Вы уверены что хотите принять предложение "{{name}}"',
    message_rejected: 'Вы уверены что хотите отклонить предложение "{{name}}"',
    message_closed: 'Вы уверены что хотите отозвать приглашение для {{name}}?',
  },
};

export const en = {
  empty_list: 'List is empty',
  titles: {
    list: 'Invitation List',
  },
  text: {
    list_info: 'Here you can see the history of Invitations sent to your address',
    alert_error: 'Unfortunately, something went wrong with the Invitation:',
    confirm: 'You have been invited to the "{{company_name}}" company. Please confirm joining the specified company',
  },
  tables: {
    list: 'Invitation History',
  },
  columns: {
    name: 'Name',
    company: 'Company',
    status: 'Status',
    expiring: 'Expiring',
    actions: 'Actions',
  },
  statuses: {
    created: 'created',
    sent: 'sent',
    accepted: 'accepted',
    rejected: 'rejected',
    closed: 'closed',
    expired: 'expired',
  },
  tooltips: {
    close: 'Revoke invite',
    accept: 'Accept invite',
    reject: 'Reject invite',
  },
  messages: {
    submit_success_accepted: 'Invite successfully accepted',
    submit_success_rejected: 'Invite successfully declined',
    submit_success_closed: 'The invitation was successfully withdrawn',
    info_next_for_unauth: 'To accept the invitation, you need to authorize or create an appropriate account ({{email}})',
    info_accept_to_company: 'Congratulations on your successful entry into the Company "{{company_name}}"',
  },
  confirms: {
    message_accepted: 'Are you sure you want to accept the invite "{{name}}"',
    message_rejected: 'Are you sure you want to reject the invite "{{name}}"',
    message_closed: 'Are you sure you want to revoke the invitation for {{name}}?',
  },
};
