import { uk as OldFields } from 'i18n/uk';
import { uk as CommonFields } from 'i18n/_single/common';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

export { i18n };

export type TArgs = {
  scope?: string | string[];
  notUseScope?: boolean;
  subScope?: string | string[];
} & Record<string, unknown>;

export type T = ((
  text: string | string[],
  tArgs?: TArgs,
) => string)
& {
  common: (text: keyof typeof CommonFields, args?: Record<string, unknown>) => string;
  old: (text: keyof typeof OldFields, args?: Record<string, unknown>) => string;
  scope: (scope: string) => (text: string, args?: Record<string, unknown>) => string;
  subScope: (subScope: string | string[]) => (text: string | string[], args?: Record<string, unknown>) => string;
};


export const useT = (scope: string | string[] = '') => {
  const { t } = useTranslation();
  const cb: T = function (text, { scope: _scope, notUseScope, subScope, ...args } = {}) {
    return t(
      [[!notUseScope && (_scope || scope), subScope, text].filter(Boolean).flat().join('.').replace(/\./g, '$'), [text].flat().join('.')],
      args,
    );
  };
  cb.common = (text, args) => t(text, args);
  cb.old = (text, args) => t(text, args);
  cb.scope = (scope: string) => (text, args) => t(`${scope}$${text}`, args);
  cb.subScope = (subScope: string | string[]) => (text: string | string[], args) => {
    return cb([subScope, text].flat(), args);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const translate = useCallback<T>(cb, [t]);
  return translate;
};
