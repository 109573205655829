import { AnyTODO } from 'core/interfaces/AnyTODO.type';

interface Cb<T = unknown> {
  (value?: T): unknown;
}

type Rtn<T extends Cb<A> | undefined, A = unknown> = T extends Cb<A> ? ((_?: A) => void) : void;

const isCb = <T>(cb?: Cb<T> | undefined): cb is Cb => {
  return cb !==undefined;
};

export type NotificateType = 'quick' | 'standard' | 'back' | 'close' | 'startLoading' | 'complete' |
  'confirm' | 'remove' | 'reset' | 'error' | 'deny' | 'success';

const CONFIG: Record<NotificateType, number[]> = {
  quick: [11],
  standard: [22],
  back: [11, 11],
  close: [11],
  startLoading: [11],
  complete: [55],
  confirm: [2552],
  remove: [99],
  reset: [44, 33, 44, 33, 44],
  error: [66, 66, 66],
  deny: [66],
  success: [22, 22],
};


class Notificate {
  static vibrate(arr: number[] | number, cb: Cb): () => void;
  static vibrate(arr: number[] | number, cb: Cb): () => void;
  static vibrate(arr: number[] | number, cb?: Cb | undefined): (() => void) | void;
  static vibrate<T extends Cb<A> | undefined, A = unknown>(arr: number[] | number, cb: T): Rtn<T, A> {
    if (isCb(cb)) {
      const y = (...args: AnyTODO) => {
        cb(...args);
        window.navigator?.vibrate?.(arr);
      };
      return y as Rtn<T, A>;
    }
    window.navigator?.vibrate?.(arr);
    return undefined as Rtn<T, A>;
  }

  static standard(cb?: Cb) {
    return Notificate.vibrate(22, cb);
  }
  static back(cb?: Cb) {
    return Notificate.vibrate([11, 11], cb);
  }
  static close(cb?: Cb) {
    return Notificate.vibrate(11, cb);
  }
  static startLoading(cb?: Cb) {
    return Notificate.vibrate(11, cb);
  }
  static complete(cb?: Cb) {
    return Notificate.vibrate(55, cb);
  }
  static confirm(cb?: Cb) {
    return Notificate.vibrate(55, cb);
  }
  static remove(cb?: Cb) {
    return Notificate.vibrate(99, cb);
  }
  static reset(cb?: Cb) {
    return Notificate.vibrate([44, 33, 44, 33, 44], cb);
  }
  static error(cb?: Cb) {
    return Notificate.vibrate([66, 66, 66], cb);
  }
  static deny(cb?: Cb) {
    return Notificate.vibrate([66], cb);
  }
  static success(cb?: Cb) {
    return this.run('success', cb);
  }

  static run(type: NotificateType, cb?: Cb) {
    return Notificate.vibrate(CONFIG[type], cb);
  }
}

export default Notificate;
