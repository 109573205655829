import { LotType } from 'constants/general';
import { SCOPE_SETTINGS } from '.';

export const SCOPE_ADDRESSES = `${SCOPE_SETTINGS}$Addresses`;

export const uk = {
  LocationSection: {
    titles: {
      [LotType.offer]: 'Локація відвантаження',
      [LotType.request]: 'Локація доставки',
    },
    address: 'Адреса',
  },
  AddressesList: {
    title: 'Адреси',
    subtitle: 'Додавайте та ховайте свої адреси на свій розсуд',
    tableName: {
      active: 'Список активних адрес',
      archive: 'Список архівних адрес',
    },
    columns: {
      name: 'Назва',
      country: 'Страна',
      details: 'Повна інформація',
      actions: 'Дії',
    },
    buttons: {
      showArchive: 'Показати Архівні',
      showActive: 'Показати Активні',
    },
  },
  AddressesCreate: {
    title: 'Створення адреси',
    subtitle: 'Створіть адресу для подальшого використання її у своїх заявках',
    formName: 'Форма створення',
  },
  AddressesEdit: {
    title: 'Редактування адреси',
    subtitle: 'У адреси можно змінити тільки назву якщо по ней були Заявки',
    formName: 'Форма редактування',
  },
  AddressesShow: {
    title: 'Перегляд адреси "{{name}}"',
    subtitle: '',
    formName: 'Деталізована інформація',
  },
  form: {
    name: {
      label: 'Назва',
      placeholder: 'Назва адреси',
    },
    index: {
      label: 'Індекс',
      placeholder: 'Індекс',
    },
    country_id: {
      label: 'Країна',
      placeholder: 'Країна',
    },
    port_id: {
      label: 'Порт/Місто',
      placeholder: 'Порт/Місто',
    },
    state_id: {
      label: 'Область',
      placeholder: 'Область',
    },
    district_id: {
      label: 'Район',
      placeholder: 'Район',
    },
    city: {
      label: 'Населений пункт',
      placeholder: 'Населений пункт',
    },
    station: {
      label: 'Станція',
      placeholder: 'Станція навантаження/вивантаження',
    },
    details: {
      label: 'Деталізація',
      placeholder: 'Уточнення за адресою',
    },
  },
  messages: {
    success: {
      edit: 'Адреса "{{name}}" успішно оновлена',
      create: 'Адреса "{{name}}" успішно створена',
      archive: 'Адреса "{{name}}" успішно заархівована',
      unarchive: 'Адреса "{{name}}" успішно активована',
    },
  },
  dialogs: {
    archive: 'Після архівування адреса "{{name}}" не буде доступна для вибору в заявках. Але його можна буде активувати назад. Продовжити ?',
    unarchive: 'Активувати "{{name}}" адресу ?',
  },
  tooltips: {
    archive: 'Перемістити адресу до Архіву',
    unarchive: 'Видалити адресу з Архіву',
    edit: 'Редагувати адресу',
  },
};


export const en: typeof uk = {
  LocationSection: {
    titles: {
      [LotType.offer]: 'Shipping location',
      [LotType.request]: 'Delivery location',
    },
    address: 'Address',
  },
  AddressesList: {
    title: 'Addresses',
    subtitle: 'Here you can hide and create addresses at your discretion',
    tableName: {
      active: 'List of active addresses',
      archive: 'List of archive addresses',
    },
    columns: {
      name: 'Name',
      country: 'Country',
      details: 'Full information',
      actions: 'Actions',
    },
    buttons: {
      showArchive: 'Show Archived',
      showActive: 'Show Active',
    },
  },
  AddressesCreate: {
    title: 'Address Creation',
    subtitle: 'Create an address to use later in your submissions',
    formName: 'Create form',
  },
  AddressesEdit: {
    title: 'Edit Address',
    subtitle: 'Only the name of the address can be changed if there were applications for it',
    formName: 'Edit form',
  },
  AddressesShow: {
    title: 'View address "{{name}}"',
    subtitle: '',
    formName: 'Detailed information',
  },
  form: {
    name: {
      label: 'Name',
      placeholder: 'Address name',
    },
    index: {
      label: 'Index',
      placeholder: 'Index',
    },
    country_id: {
      label: 'Country',
      placeholder: 'Country',
    },
    port_id: {
      label: 'Port/City',
      placeholder: 'Port/City',
    },
    state_id: {
      label: 'State',
      placeholder: 'State',
    },
    district_id: {
      label: 'District',
      placeholder: 'District',
    },
    city: {
      label: 'Locality',
      placeholder: 'Locality',
    },
    station: {
      label: 'Station',
      placeholder: 'Loading/unloading station',
    },
    details: {
      label: 'Details',
      placeholder: 'Address details',
    },
  },
  messages: {
    success: {
      edit: 'Address "{{name}}" successfully updated',
      create: 'Address "{{name}}" successfully created',
      archive: 'Address "{{name}}" successfully archived',
      unarchive: 'Address "{{name}}" successfully activated',
    },
  },
  dialogs: {
    archive: 'After archiving, "{{name}}" address will not be available for selection in applications. But it can be turned back on. Continue ?',
    unarchive: 'Activate "{{name}}" address ?',
  },
  tooltips: {
    archive: 'Move address to Archive',
    unarchive: 'Remove address from Archive',
    edit: 'Edit address',
  },
};


export const ru: typeof uk = {
  LocationSection: {
    titles: {
      [LotType.offer]: 'Локация отгрузки',
      [LotType.request]: 'Локация доставки',
    },
    address: 'Адрес',
  },
  AddressesList: {
    title: 'Адреса',
    subtitle: 'Здесь можно удалять и добавлять адреса по своему усмотрению',
    tableName: {
      active: 'Список активных адресов',
      archive: 'Список архивных адресов',
    },
    columns: {
      name: 'Название',
      country: 'Страна',
      details: 'Полная информация',
      actions: 'Действия',
    },
    buttons: {
      showArchive: 'Показать Архивные',
      showActive: 'Показать Активные',
    },
  },
  AddressesCreate: {
    title: 'Создание адреса',
    subtitle: 'Создайте адрес для последующего испльзования его в своих заявках',
    formName: 'Форма создания',
  },
  AddressesEdit: {
    title: 'Редактирование адреса',
    subtitle: 'В адресе можно изменить только название, если по нему были Заявки',
    formName: 'Форма редактирования',
  },
  AddressesShow: {
    title: 'Просмотр адреса "{{name}}"',
    subtitle: '',
    formName: 'Детализированная информация',
  },
  form: {
    name: {
      label: 'Название',
      placeholder: 'Название адреса',
    },
    index: {
      label: 'Индекс',
      placeholder: 'Индекс',
    },
    country_id: {
      label: 'Страна',
      placeholder: 'Страна',
    },
    port_id: {
      label: 'Порт/Город',
      placeholder: 'Порт/Город',
    },
    state_id: {
      label: 'Область',
      placeholder: 'Область',
    },
    district_id: {
      label: 'Район',
      placeholder: 'Район',
    },
    city: {
      label: 'Населенный пункт, ',
      placeholder: 'Населенный пункт, ',
    },
    station: {
      label: 'Станция',
      placeholder: 'Станция погрузки/выгрузки',
    },
    details: {
      label: 'Детализация',
      placeholder: 'Уточнения по адресу',
    },
  },
  messages: {
    success: {
      edit: 'Адрес "{{name}}" успешно обновлен',
      create: 'Адрес "{{name}}" успешно создан',
      archive: 'Адрес "{{name}}" успешно заархивирован',
      unarchive: 'Адрес "{{name}}" успешно активирован',
    },
  },
  dialogs: {
    archive: 'После архивирования адрес "{{name}}" не будет доступен к выбору в заявках. Но его можно будет активировать обратно. Продолжить ?',
    unarchive: 'Активировать "{{name}}" адрес ?',
  },
  tooltips: {
    archive: 'Переместить адрес в Архив',
    unarchive: 'Удалить адрес из Архива',
    edit: 'Редактировать адрес',
  },
};
