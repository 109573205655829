export const SCOPE_FORMS_ERROR = ['forms', 'errors'];

export const uk = {
  not_all_entered: 'Не всі обов\'язкові поля введені',
  mixed: {
    requred: 'Обов\'язкове поле',
  },
  string: {
    max: 'Кількість символів не може перевищувати {{max}}',
    min: 'Кількість символів не меньше {{min}}',
    email: 'Невірно введений e-mail',
    emailOrPhone: 'Невірний e-mail чи телефон',
    phone: 'Неправильний формат (+380509999999)',
  },
  number: {
    max: 'Має бути менше {{max}}',
    min: 'Має бути більше {{min}}',
  },
};

export const en: typeof uk = {
  not_all_entered: 'Not all required fields entered',
  mixed: {
    requred: 'Required field',
  },
  string: {
    max: 'The number of characters cannot exceed {{max}}',
    min: 'The number of characters cannot be less {{min}}',
    phone: 'Wrong format (+380509999999)',
    emailOrPhone: 'Wrong email/phone format',
    email: 'Wrong email address',
  },
  number: {
    max: 'Must be less than {{max}}',
    min: 'Must be more than {{min}}',
  },
};

export const ru: typeof uk = {
  not_all_entered: 'Не все обязательные поля введены',
  mixed: {
    requred: 'Обязательное поле',
  },
  string: {
    max: 'Количество символов не может превышать {{max}}',
    min: 'Количество символов не меньше {{min}}',
    email: 'Неверно введен e-mail',
    emailOrPhone: 'Неверный формат e-mail или телефона',
    phone: 'Неправильный формат (+380509999999)',
  },
  number: {
    max: 'Должно быть меньше {{max}}',
    min: 'Должно быть больше {{min}}',
  },
};
