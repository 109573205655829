import { FORMAT_DATES } from 'constants/general';
import moment from 'moment';

const FROM_NOW_FORMAT = 'fromNow';
export enum FormatTypes {
  common = 'common',
  dates = 'dates',
}
const FormatsEnum: Record<FormatTypes, string[]> = {
  common: [FORMAT_DATES.time24, FORMAT_DATES.simpleDatetime, FORMAT_DATES.full, FROM_NOW_FORMAT],
  dates: [FORMAT_DATES.main, FORMAT_DATES.dayMonth, FROM_NOW_FORMAT],
};

export class DateTimeHelper {
  static format(date: string, formatIndex = 0, formatType: FormatTypes = FormatTypes.common): string {
    const format = FormatsEnum[formatType][formatIndex % FormatsEnum[formatType].length];
    if (format === FROM_NOW_FORMAT) {
      return moment(date).fromNow();
    }
    return moment(date).format(format);
  }
  static isFuture(date: string): boolean {
    return moment(date).isAfter();
  }
}
