import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, API_ROUTES } from 'modules/Api';
import { FactoryBuilder, factoryInitState } from './helpers';
import { IAdditionalApiStatuses } from './interfaces';

export { useTsSelector, useTsDispatch } from 'store/store.types';

interface PartOveralls {
  count: number;
  volume: number;
}
interface Overalls {
  offer: PartOveralls;
  request: PartOveralls;
}

export interface IState {
  overalls?: Overalls;
}

const initialData: IState = {};
const initialState: IState & IAdditionalApiStatuses = factoryInitState(initialData);

export const NAME = 'markets';
const scope = `${NAME}`;

export const getOveralls = createAsyncThunk(
  `${scope}/getOveralls`,
  (): Promise<IState> => {
    return API.get<Overalls>(`${API_ROUTES.markets}/overalls`).then(res => ({ overalls: res.data }));
  },
);


export const {
  reducer,
  // actions: {},
} = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: builder => new FactoryBuilder(builder)
    .buildAction(getOveralls),
});

export default reducer;
