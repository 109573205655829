export const SCOPE_AUTOCOMPLETE = 'autocomplete';

export const uk = {
  groupBy: {
    my: 'Мої',
    others: 'Решта',
  },
  countries: {
    title: 'З якими ринками працюєте',
    label: 'Ринок',
    placeholder_c0: 'Ринок',
    placeholder_one: 'Вибран {{count}} ринок',
    placeholder_few: 'Вибрано {{count}} ринку',
    placeholder_many: 'Вибрано {{count}} ринків',
  },
  cultures: {
    title: 'Продукт',
    label: 'Виберіть продукт',
    placeholder: 'Жовтий горох',
  },
  location_id: {
    label: 'Адреса',
    placeholder: 'Адреса місцезнаходження',
  },
  port_options: {
    label: 'Порт / Місто',
    placeholder: 'Порт / Місто',
  },
  ports: {
    title: 'Ваші призначення',
    label: 'Призначення',
    placeholder_c0: 'Порт / Місто',
    placeholder_one: 'Вибрано {{count}} призначення',
    placeholder_few: 'Вибрано {{count}} призначення',
    placeholder_many: 'Вибрано {{count}} призначень',
  },
  shipments: {
    title: 'Типи відвантажень',
    label: 'Тип',
    placeholder_c0: 'Тип',
    placeholder_one: 'Вибран {{count}} тип',
    placeholder_few: 'Вибрано {{count}} типи',
    placeholder_many: 'Вибрано {{count}} типів',
  },
};

export const en = {
  groupBy: {
    my: 'My',
    others: 'Others',
  },
  countries: {
    title: 'Markets you are working at',
    label: 'Market',
    placeholder_c0: 'Market',
    placeholder_one: 'Selected {{count}} country',
    placeholder_other: 'Selected {{count}} countries',
  },
  cultures: {
    title: 'Product',
    label: 'Choose product',
    placeholder: 'Yellow peas',
  },
  location_id: {
    label: 'Address',
    placeholder: 'Location address',
  },
  port_options: {
    label: 'Port / City',
    placeholder: 'Port / City',
  },
  ports: {
    title: 'Your destinations',
    label: 'Destination',
    placeholder_c0: 'Port / City',
    placeholder_one: 'Selected {{count}} destination',
    placeholder_other: 'Selected {{count}} destinations',
  },
  shipments: {
    title: 'Shipment types',
    label: 'Type',
    placeholder_c0: 'Type',
    placeholder_one: 'Selected {{count}} type',
    placeholder_other: 'Selected {{count}} types',
  },
};

export const ru = {
  groupBy: {
    my: 'Мои',
    others: 'Остальные',
  },
  countries: {
    title: 'С какими рынками работаете',
    label: 'Рынок',
    placeholder_c0: 'Рынок',
    placeholder_one: 'Выбран {{count}} рынок',
    placeholder_few: 'Выбрано {{count}} рынка',
    placeholder_many: 'Выбрано {{count}} рынков',
  },
  cultures: {
    title: 'Продукт',
    label: 'Выберите продукт',
    placeholder: 'Желтый горох',
  },
  location_id: {
    label: 'Адрес',
    placeholder: 'Адрес местонахождения',
  },
  port_options: {
    label: 'Порт / Город',
    placeholder: 'Порт / Город',
  },
  ports: {
    title: 'Ваши напрвления',
    label: 'Направление',
    placeholder_c0: 'Порт/ Город',
    placeholder_one: 'Выбрано {{count}} направление',
    placeholder_few: 'Выбрано {{count}} направления',
    placeholder_many: 'Выбрано {{count}} направлений',
  },
  shipments: {
    title: 'Виды отгрузок',
    label: 'Тип',
    placeholder_c0: 'Тип',
    placeholder_one: 'Выбран {{count}} тип',
    placeholder_few: 'Выбрано {{count}} типа',
    placeholder_many: 'Выбрано {{count}} типов',
  },
};
