import { uk } from './uk';

export const en: typeof uk = {
  '№': '#',
  'Вход через Email или телефон': 'Login by Email or phone',
  'Вход через пин / ссылку': 'Login by pin / link',
  'Обязательное поле': 'Required field',
  'Пароль должен быть больше 5 символов': 'Password should contain more than 5 symbols',
  'Пароль не должен быть пустым': 'Password should not be empty',
  'Пароль не может содержать пробелы': 'Password cannot contain spaces',
  'Неправильный логин или пароль': 'Wrong login or password',
  'Срок действия ссылки истек': 'Link is expired',
  'Добро пожаловать': 'Welcome',
  'Начни торговать прямо сейчас': 'Start trading now',
  'Завести аккаунт': 'Sign up',
  'Вход через': 'Login via',
  'Email': 'Enter your email',
  'EmailPhone': 'Enter your email or phone',
  'Пароль': 'Password',
  'Забыли пароль?': 'Forgot password?',
  'Вход': 'Sign in',
  'Пароли не совпадают': 'Passwords don\'t match',
  'Требуется подтверждение пароля': 'Password confirmation is required',
  'Ваш пароль изменен': 'Your password has been changed successfully',
  'Ссылка неактивна': 'Link is inactive',
  'Новый пароль': 'New password',
  'Введите новый пароль': 'Enter new password',
  'Повторите пароль': 'Repeat password',
  'Сбросить пароль': 'Reset password',
  'Вернуться к авторизации': 'Sign in',
  'Ссылка для восстановления пароля была отправлена на {{mail}}': 'Restore password link has been sent to the {{mail}}',
  'Пользователя не существует либо невозможно сменить пароль': 'User doesn\'t exist or it is not possible to change password',
  'Мы вышлем вам инструкции по восстановлению пароля.': 'We will send you instructions to restore password.',
  'Обычная регистрация': 'Regular registration',
  'Быстрая регистрация': 'Quick registration',
  'Пожалуйста, укажите вид деятельности': 'Please, chose your role',
  'Извините, этот адрес e-mail уже занят другим пользователем': 'Sorry, but this email address is assigned to another user',
  'Регистрация': 'Registration',
  'Уже есть аккаунт?': 'Already have an account?',
  'Авторизоваться': 'Sign in',
  'Вид деятельности': 'Role',
  'Покупатель': 'Buyer',
  'Поставщик': 'Seller',
  'Зарегистрироваться': 'Sign up',
  'Нажимая на кнопку': 'Upon clicking on button',
  '«Зарегестрироваться», я даю согласие на обработку персональных данных, соглашаюсь с ': '"Sign up", I agree to process my personal data and agree with ',
  'публичной офертой ООО «Glendeal» и правилами сайта.': 'public offer from Glendeal and Terms & Services.',
  'Для создания заявки необходимо подтвердить ваш email': 'To create a request, you have to confirm your email',
  'Необходимо подтвердить ваш email': 'You have to confirm your email',
  'Ваша компания еще не подтверждена': 'Your company has not been verified yet',
  'Подтвердите, пожалуйста, свой телефон': 'Please confirm your phone number',
  'Для создания заявки необходимо зарегистрировать компанию': 'To create a request, you have to register a company',
  'Необходимо зарегистрировать компанию': 'Нou have to register a company',
  'Создать заявку': 'Create request',
  'Сообщение успешно отправлено': 'Message sent',
  'Техподдержка': 'Tech support',
  'Мои предложения': 'Offers',
  'Необходимо разъяснение': 'Clarification needed',
  'Отзыв': 'Review',
  'Обратная связь': 'Feedback',
  'Тема': 'Subject',
  'Текст сообщения': 'Enter your text',
  'Отправить': 'Send',
  'Письмо успешно отправлено на почту {{email}}': 'Message sent',
  'Электронное письмо можно отправлять повторно только один раз в 5 минут': 'Email can be resent only once per 5 minutes',
  'Вам на электронную почту было отправлено письмо для подтверждения адреса.': 'An email has been sent to you to verify your email address.',
  'Ваша компания находится на верификации. Благодарим за понимание.': 'Your company is under verification. Thank you for understanding.',
  'Для доступа к созданию Заявок, подтвердите, пожалуйста, телефон.': 'To access the creation of Lot, please confirm your phone number.',
  'Повторить письмо': 'Send again',
  'Отменить': 'Cancel',
  'Сохранить': 'Save',
  'Отмена': 'Cancel',
  'Выбрать': 'Select',
  'Вы были приглашены в компанию {{company_name}}. Пожалуйста, подтвердите присоединение к указанной компании': 'You have been invited to the {{company_name}} company. Please, confirm joining to this company',
  'Вы уверены что хотите удалить {{name}} из компании?': 'Are you sure you want to delete {{name}} from company?',
  'Подтвердить': 'Confirm',
  'Фильтр': 'Filter',
  'Тип заявки': 'Type of request',
  'Все': 'All',
  'Покупка': 'Buy',
  'Продажа': 'Sell',
  'Компания': 'Company',
  'Название компании': 'Company name',
  'Базис поставки': 'Basis',
  'Период': 'Range',
  'Продукт': 'Product',
  'Название продукта': 'Product name',
  'Применить фильтр': 'Apply filter',
  'Сбросить фильтр': 'Reset filter',
  'Если вы захотите пройти туториал еще раз, вы можете нажать кнопку помощь и выбрать пройти туториал': 'If you want to go through the tutorial again, you can click the help button and choose to go through the tutorial',
  'Назад': 'Back',
  'от': 'from',
  'Скрыть': 'Dismiss',
  'Дальше': 'Next',
  'Завершить!': 'Complete!',
  'Все продукты': 'All products',
  'Спрос': 'Bid',
  ' т': ' t',
  'Уведомления': 'Notifications',
  'Прочитать все': 'Read all',
  'Показать все': 'Show all',
  'Загрузите фото для профиля': 'Upload photo for your profile',
  'Добавить фото': 'Add photos',
  'Фулл хаус': 'It is full',
  'Вы добавили максимально возможное количество фото ({{MAX_UPLOADED_FILES}}). Снимаем шляпу.': 'You\'ve added as many photos ({{MAX_UPLOADED_FILES}}) as possible.',
  'Формат файлов (до {{MAX_UPLOADED_FILES}} штук) JPG, PNG': 'File format JPG, PNG. Up to {{MAX_UPLOADED_FILES}} files.',
  'Некорректный тип у {{count}} файл(а)ов': 'Incorrect type for {{count}} file(s)',
  'Превышен лимит - {{MAX_UPLOADED_FILES}} файл(ов)': 'Limit for {{MAX_UPLOADED_FILES}} files has been exceeded',
  'Ваше имя': 'Your name',
  'Рынок': 'Market',
  'Рынок агрокультур': 'Crop Market',
  'Помощь': 'Help',
  'Настройки': 'Settings',
  'Выйти': 'Exit',
  'Такая почта уже зарегистрированная, перейдите на страницу авторизации и авторизируйтесь': 'Account already exists, visit sign-in page for authorization',
  'Проверте почту для быстрой авторизации или попитайтесь снова через 2 минуты': 'Check email for fast authentication or try again within 2 minutes',
  'Сначала создайте учетную запись, для быстрой авторизации': 'Create account first, for fast authorizations',
  'Почта не активированная, проверте почту для активации': 'Account is not active, check email for activation',
  'Одноразовый код отправлен на почту {{email}}': 'One-time code has been sent to email {{email}}',
  'Проверьте введенные данные, неправильный email или пин': 'Check the entered data, not the correct email address or pin',
  'Пин код': 'Pin',
  'Ссылка': 'Link',
  'Pin': 'Pin',
  'Заявка': 'Request',
  'Тип': 'Type',
  'Информация': 'Information',
  'Добавлено в избранное': 'Added to favourites',
  'Удалено из избранного': 'Deleted from Favourites',
  'Ошибка': 'Error',
  'т': 't',
  'в': 'in',
  'Поиск': 'Search',
  'Смотреть все': 'Vew all',
  'По данному запросу нет результатов': 'There are no results for this search',
  'Заявки': 'Requests',
  'Мои заявки': 'My requests',
  'Мои торги': 'My trades',
  'Культура': 'Culture',
  'Добавлена заявка на продажу': 'Application form for buy or sale',
  'Добавлена заявка на покупку': 'Application form for buy or sale',
  'день': 'day',
  'дня': 'days',
  'дней': 'days',
  'час': 'hour',
  'часа': 'hours',
  'часов': 'hours',
  'минута': 'minute',
  'минуты': 'minutes',
  'минут': 'minutes',
  'запрос': 'request',
  'запроса': 'bids',
  'запросов': 'bids',
  'лот': 'offer',
  'лота': 'offers',
  'лотов': 'offers',
  'Публичное соглашение': 'Public agreement',
  'Политика конфиденциальности': 'Privacy policy',
  'Glendeal - платформа для торговли агропродукцией': 'Trading platform for agricultural commodities',
  'Размести заявку и жди выгодное предложение,': 'Place an order and wait for a good offer,',
  'получил предложение - начни торги и фиксируй сделку': 'then take the advantage of trading, and finally, secure the profit',
  'Спрос и Предложение': 'Supply and Demand',
  'Только актуальный объем спроса и предложения с указанием количества лотов доступных к торгам прямо сейчас.': 'Find deals with relevant data about Supply and Demand volumes with real-time number of lots currently available for trading.',
  'Заявки на торги': 'Trading requests',
  'Инструменты позволяющие создавать заявки на покупку и продажу продукции в несколько кликов. ': 'Tackle the request creation process easily with just a few clicks. A simple set of tools will let you place a buy or a sell order within seconds. ',
  'Торги и оповещения': 'Trading notifications',
  'Торги по международным правилам с подтверждением сделки. Система он-лайн оповещений в торгах не даст пропустить выгодные предложения.': 'The platform functions according to the rules of international trading, with each transaction requiring confirmation. You won’t skip a profitable deal with the online notification system in place.',
  'Поставщики и покупатели': 'Buyers and suppliers',
  'Система верификации пользователей платформы гарантирует надежность заключенных сделок. Отзывы и рейтинги пользователей повысят качество сделок.': 'Reviews of marketplace users will allow to rise volume and quality of successful deals. Additionally, we provide a system of user ratings and reviews that increase the quality of trading.',
  'Способы доставки': 'Shipment options',
  'Доставка продукции любым видом транспорт с мгновенным расчетом стоимости доставки. Подробная калькуляция доставки предоставит вам оптимальную цену под вашу задачу.': 'Any type of shipping of the commodity with an instant shipping estimate. Depends on your goal, you may choose the most optimal shipping option by calculating it and receiving an accurate quote.',
  'John Cooper': 'John Cooper',
  '“The company is a priceless tool that helps us drive Sales and Support through every step of the funnel.”': '“The company is a priceless tool that helps us drive Sales and Support through every step of the funnel.”',
  'Wade Warren': 'Wade Warren',
  'Esther Howard': 'Esther Howard',
  'Войти': 'Sign in',
  'Платформа': 'Platfrom',
  ' для торговли агропродукцией': ' Trading platform for agricultural commodities',
  'Размести заявку и жди выгодное предложение, получил предложение, участвуй в торгах и фиксируй сделку.': 'Place an order and wait for a good offer, then take the advantage of trading, and finally, secure the profit.',
  'Начать торговать': 'Start trading now',
  'Покупка и продажа быстро и выгодно': 'Quick and efficient way to trade crops',
  'Вам это знакомо': 'Sounds familiar',
  'Продавцам': 'For Sellers',
  'Устали от бесконечных  звонков': 'Tired of endless calls and inquiries',
  'Размещая заявку вы ждете выгодные предложения и вступаете в торги в удобное для вас время': 'Place a request and wait for the offers. Receive a notification and engage with trading whenever you see a good offer',
  'Не предлагают справедливую цену': 'Failing to find a fair price matching the offer',
  'Большой выбор покупателей по всему миру будет торговаться за вашу продукцию предлагая максимальную цену': 'Take advantage of the large number of Buyers around the world, offering you the highest possible price',
  'Покупателям': 'For Buyers',
  'Не можете найти нужный товар': 'Struggling to find the right product',
  'Платформа собрала множество поставщиков предлагающих широкий ассортимент необходимой вам продукции': 'Find nearly all types of crops. At Glendeal, we have a large variety of suppliers offering a wide range of agricultural products',
  'Не надежные поставщики': 'Fraud and unreliable suppliers',
  'Личный контакт с поставщиком и отзывы пользователей помогут избежать срыва сделки': 'Personal contact with supplier and customer reviews would help to prevent collapse of the deal',
  'Платформа Glendeal': 'Glendeal Platform',
  'Оптимальные решения выгодной торговли для агробизнеса': 'Your best-case solution for profitable agricultural commodity trading',
  'Покупай по оптимальной цене': 'Buy crops at fair price',
  'Платформа предоставляет широкий ассортимент агро продукции от поставщиков по рыночным ценам. Только актуальные лоты на платформе с торгами в реальном режиме времени.': 'Our platform provides a large variety of agricultural commodities offered by suppliers at a market price. Only relevant lots that are being traded in real-time.',
  'Продавай выгодно и легко': 'Sell easily and with profit',
  'Платформа предоставляет доступ к покупателям по всему миру, дающий возможность продать продукцию по максимальной цене. Он-лайн уведомления не дадут упустить выгодную сделку.': 'We provide an opportunity to reach partners on a global scale, making it possible to sell at the highest price. Our real-time notification feature will ensure you won’t miss out on potentially profitable deals.',
  'Как это работает': 'How it works',
  'Простой и интуитивно понятный интерфейс поможет вам быстро во всем разобраться': 'Simple yet intuitive interface will help you start trading within minutes',
  'Зарегистрируйтесь на платформе': 'Sign up at our platform',
  'Создайте заявку на покупку или продажу': 'Place a Buy or Sell request',
  'Участвуйте в торгах': 'Participate in trading',
  'Получите документ о подтверждении сделки': 'Get a deal confirmation',
  'Что мы решаем': 'What we do',
  'Мы не просто соединили поставщика и покупателя, мы создали благоприятную среду общения и эффективные инструменты для торговли': 'At Glendeal, we’re not only connecting suppliers and buyers, but also provide a comfortable environment for communication and tools for efficient trading',
  'Надежный и безопасный сервис': 'Secure and reliable service',
  'Проверенные контрагенты и сервис выполнения контрактов гарантирует успешность сделки': 'We verify our conterparties to ensure the successful contract execution and deal settlement',
  'Простые и понятные инструменты': 'Simple and intuitive tools for trading',
  'Инструменты максимально облегчающие процесс заведения заявок на покупку и продажу': 'Our set of tools will help to make your trading process simple and efficient',
  'Большой спрос и предложение': 'Great amounts for both Supply and Demand',
  'У нас вы найдете широкий ассортимент продукции собранный по всему миру': 'At Glendeal, you will find a rich variety of comodities from all around the world',
  'Поддержка и консультация': 'Support and Consulting at your demand',
  'Платформа - гарант безопасности и поддержка. Он-лайн доступ по возникшим у вас вопросам и для консультации': 'We’ve got your back when it comes to both security and support. Working around the clock, we ensure all questions and concerns are resolved in a timely manner',
  'Исполнение контрактов "под ключ"': 'Turnkey based contract execution',
  'Выполнение контрактов под ключ с соблюдение всех норм международного права с полной ответственностью': 'Every proccessed contract is law-compliant and protected by international law. We ensure the product gets from the first supplier to end customer with full liability from our side',
  'Отзывы о нас': 'Our clients’\nreviews',
  'Начать торги прямо сейчас': 'Start trading right now',
  'Зарегистрируйтесь и разместите заявку, участвуйте в торгах и заключайте выгодные контракты.': 'Sign up to place a request, proceed with trading, and secure profitable contracts.',
  'Заполните пропущенные поля': 'Fill in the missing fields',
  'Создание заявки': 'Request creating',
  'Для начала торгов создайте заявку, для этого нажмите эту кнопку': 'To start a trade, create request by clicking on this button',
  'Выбор типа заявки': 'Choose of request type',
  'Выберите заявку на продажу или покупку товара': 'Choose request for sale or purchase of goods',
  'Выбор продукта': 'Choose product',
  'Выберите продукт и укажите спецификацию вашего продукта': 'Choose product and set specifications of your product',
  'Условия поставки': 'Terms of delivery',
  'Укажите условия поставки и тип отправки': 'Set terms of delivery and dispatch type',
  'Детали поставки': 'Delivery details',
  'Укажите тип упаковки, количество единиц транспорта, стандарт загрузки и есть ли ограничения и количество партий': 'Set the type of packaging, number of transport, load standard and if there are limitations and number of batches',
  'Условия оплаты': 'Terms of payment',
  'Выберите условия оплаты': 'Choose terms of payment',
  'Общие сведения': 'General information',
  'Укажите обьем, цену и валюту': 'Set value, price and currency',
  'Обязательные документы': 'Required documents',
  'Укажите обязательные транспортные документы для товара': 'Set the required transport documents for goods',
  'Дополнительные документы': 'Additional documents',
  'Укажите дополнительные транспортные документы для товара': 'Set the additional transport documents for goods',
  'Дополнительные сведения': 'Additional information',
  'Укажите происхождение и дату производства товара, срок действия заявки и есть ли специфика загрузки в транспорт': 'Set the origin and date of good manufacture, validity period and if there are load specification',
  'Для подтверждения создания заявки нажмите эту кнопку': 'To confirm creating of request, press this button',
  'В меню мои заявки вы можете увидеть все созданные вами заявки': 'In menu “My requests” you can see all requests created by you',
  'В уведомлениях вы получите сообщения о торгах по вашим заявкам и созданным новым заявкам по которым вы сами можете начать торги': 'In notifications you receive messages about trades for your requests and about new created requests which you can start trading with',
  'Список компаний': 'List of companies',
  'Компании от которых получены предложения по вашей заявке': 'Companies from which you received inquiries for your request',
  'Параметры заявки по которой ведутся торги': 'Terns of request which trading is conducted',
  'Своя заявка': 'Own request',
  'Изменение своей встречной заявки': 'Change of your counter-request',
  'Выход из торгов': 'Leave trade',
  'Если вы хотите закрыть торги нажмите эту кнопку': 'If you would like to close trade then press this button',
  'Предложить цену': 'Offer price',
  'Предложите цену и срок ее действия по заявке справа': 'Offer price and its validity time for request to the right',
  'Предложение цены': 'Price offer',
  'Установите желаемую цену за единицу товара и время действия данного предложения': 'Set the desired price per item and the time within this offer is valid',
  'Чат торгов': 'Trade chat',
  'Чат торгов с уведомлениями об изменении заявок, вашими и встречными предложениями': 'Trade chat with notifications about request changes with your or counter-offers',
  'Подтверждение торгов': 'Accept offer',
  'Для подтверждения сделки нажмите эту кнопку': 'To confirm deal press this button',
  'Успех': 'Success',
  'Поздравляем Вы совершили вашу первую сделку в Glendeal.': 'Congratulations! You have completed your first deal at Glendeal.',
  'Загрузка изображений': 'Download images',
  'Загрузите фото вашего товара, а так же копии сертификатов': 'Upload photo of your good and certificates copies',
  'Укажите дополнительные сведения по отправке товара': 'Provide additional information about goods delivery',
  'Для создания заявок необходимо заполнить контактные данные': 'For creating requests, please, provide contact details',
  'Количество символов не может превышать {{count}}': 'The length cannot be more than {{count}} symbols',
  'Имя может содержать только буквы и -': 'The name can only contain letters and -',
  'Поле не может состоять из пробелов': 'Field cannot consist of spaces only',
  'Телефон не может содержать пробелы': 'Phone number cannot contain spaces',
  'Название компании не может состоять из пробелов': 'Company name cannot consists of spaces only',
  'Название компании не должно быть больше 50 символов': 'Company name cannot be more than 50 symbols',
  'Компания с таким именем уже зарегистрирована': 'Company with this name is already registered',
  'Страна': 'Country',
  'Нет вариантов': 'No options',
  'Открыть': 'Open',
  'Закрыть': 'Close',
  'Очистить': 'Clear',
  'Украина': 'Ukraine',
  'Контактное лицо': 'Contact person',
  'Имя': 'Name',
  'Фамилия': 'Last name',
  'Телефон': 'Phone number',
  'Адрес': 'Address',
  'Город, улица, номер дома': 'City, street, building number',
  'Продолжить': 'Continue',
  'Ваши продуктовые группы': 'Your product categories',
  'Категория': 'Category',
  'Бобовые': 'Legumes',
  'Все доступные продукты добавлены': 'Added all available products',
  'С какими рынками работаете': 'Markets you are working at',
  'Все доступные страны добавлены': 'Added all available countries',
  'Виды отгрузок': 'Shipment types',
  'Авто': 'Car',
  'Информация о компании': 'Company information',
  'до 5': 'to 5',
  'больше 500': 'more than 500',
  'больше 2500': 'more than 2500',
  'Нет': 'No',
  'Да': 'Yes',
  'Заполнить позже': 'Fill in later',
  'Принять предложение': 'Accept offer',
  'Выйти из торгов': 'Leave trade',
  'На вашу почту отправлено письмо с подтверждением и контактами. Спасибо за бизнес!': 'Confirmation and contacts has been sent to your email. Thank you for business!',
  'Торг открыт, можете его продолжить или выйти': 'An auction is open, you can continue or exit',
  'Заявка была удалена': 'Request is closed',
  'Заявка на ': 'Request for ',
  'покупку': 'buy',
  'продажу': 'sell',
  'Продавец': 'Seller',
  'Спецификация продукта': 'Product specification',
  'Условия поставки и оплаты': 'Terms of delivery and payment',
  'Расположение': 'Location',
  'Транспортные документы': 'Transport documents',
  'Дополнительная информация': 'Additional information',
  'Цена подтверджена': 'Price confirmed',
  'Ставка': 'Bid',
  'Предложение': 'Offer',
  'Высокая цена': 'High price',
  'Неадекватное качество': 'Low quality',
  'Не в нужном объеме': 'No required value',
  'Больше не актуально': 'Not relevant anymmore',
  'Низкая цена': 'Low price',
  'Заявка продана': 'Request is closed',
  'Выберите причину': 'Choose a reason',
  'Выберите продукт из списка': 'Choose product from list below',
  'Сумма не должна привышать 100%': 'The length cannot be more than 100 symbols',
  'Сумма не должна привышать 10000': 'The length cannot be more than 100 symbols',
  'Не более 999 999': 'Not more 999 999',
  'Сумма не должна привышать 32767': 'The sum cannot be higher than 32767',
  'Длинна имени не должна привышать 100 символов': 'Name cannot be more than 100 symbols',
  'Длинна названия не должна привышать 200 символов': 'Name cannot be more than 100 symbols',
  'Дата не может быть больше чем текущий год или меньше 100 лет': 'Data cannot be greater than current year or lower then 100 years',
  'Количество дней не должно привышать 100': 'The length cannot be more than 100 symbols',
  'Длинна комментария не должна превышать 500 символов': 'The note length cannot be more than 500 symbols',
  'Форма заполнения заявки на покупку или продажу': 'Application form for buy or sale',
  'Редактирование заявки на покупку или продажу': 'Editing a buy or sell order',
  'Форма встречной заявки на покупку или продажу': 'Application of a counter order to buy or sell',
  'Укажите тип заявки': 'Choose a request type',
  'Для создания заявки пользователь должен быть активирован и присоединен с компанией': 'To create a request, user should be activated and assigned to company',
  'Происхождение': 'Origin',
  'Дата производства': 'Crop',
  'Заявка действительная до': 'Offer is valid until',
  'меньше 15': 'less then 15',
  'больше 45': 'more than 45',
  'Количество бесплатных дней в порту': 'Number of free days in port',
  'Не подходящие судоходные линии': 'Unsuitable shipping lines',
  'Необходимое транзитное время (дней)': 'Required transit time (days)',
  'Надпись на этикетке': 'Label inscription',
  'Базис': 'Basis',
  'Выберите продукт': 'Choose product',
  'Желтый горох': 'Yellow peas',
  'Валюта': 'Currency',
  'Дни': 'Days',
  'Документы': 'Documents',
  'Выберите из списка требуемые оригинальные документы': 'Choose the required original documents from the list',
  'Обязательные транспортные документы': 'Required transport documents',
  'Дополнительные транспортные документы': 'Additional transport documents',
  'Часы': 'Hours',
  'Фото': 'Photo',
  'Загрузите фото продукта и спецификации': 'Upload photo of product and specification',
  'Ограничение': 'Limitation',
  'Пожалуйста, введите ограничение на загрузку в контейнер, если оно отличается от стандарта загрузки': 'Please, enter the limitation if it is different to load standard',
  'Стандарт загрузки': 'Load standard',
  'Внимание, вы изменяете обьем загрузки в контейнер. Пожалуйста, используйте существующий стандарт': 'Please, pay attention that you are changing the load standard. Please, use the default one if possible',
  'Порт / Город': 'Port / City',
  'Единиц транспорта': 'Number of transport',
  'Упаковка': 'Package',
  'Партии': 'Batches',
  'Цена / тонна': 'Price / tons',
  'Период отгрузки': 'Shipment period',
  'Тип отправки': 'Dispatch type',
  'Объем / тонны': 'Volume / tons',
  'Обратите внимание на мертвый фрахт {{volumeLowerVolumeLoad}} тонн.': 'Pay attention to dead freight {{volumeLowerVolumeLoad}} tons.',
  'Пожалуйста, убедитесь что все контейнеры имеют максимальную загрузку': 'Please make sure all containers have their maximum load',
  'Указанный объем превышает максимальный объем загрузки на {{volumeMoreVolumeLoad}} тонн.': 'The specified value is greater than maximum load value for {{volumeMoreVolumeLoad}} tons.',
  'Пожалуйста, убедитесь что вы правильно указали объем': 'Please, make sure that you entered the correct value',
  'Подтверждение почты': 'Email confirmation',
  'Мы отправили письмо на адрес ': 'We sent a letter to the address ',
  ' чтобы подтвердить действительность вашего электронного адреса. После получения письма перейдите по предоставленной ссылке для завершения регистрации или авторизации.': ' to confirm the validity of your email address. After receiving the letter, go to the link provided to complete the registration or authorization.',
  'Если вы не получили письмо, ': 'If you haven\'t received the letter, ',
  'повторно отправьте письмо ': 're-send an email ',
  'с подтверждением': 'Confirmered',
  'Избранное': 'Favourites',
  'В списке нет продуктов': 'There are no products in the list',
  'Для создания заявки на покупку или продажу интересующей вас продукции вы должны сперва заполнить информацию о себе и вашей компании (см. ': 'To create a request for the purchase or sale of products you are interested in, you must first fill in information about yourself and your company (see ',
  'Чтобы создать заявку:': 'To create a request:',
  '1) нажмите на кнопку “Создать заявку” из любой части платформы.': '1) click on the “Create request” button from any part of the platform.',
  '2) на открывшейся странице выберите тип заявки в зависимости от того, хотите ли вы продать или купить продукцию на платформе.': 'on the page that opens, select the type of request depending on whether you want to sell or buy products on the platform.',
  '3) выберите продукт который вы желаете купить/продать.': '3) select the product you want to buy / sell.',
  '4) загрузите фото продукта или документов по продукту (для продажи).': '4) upload a product photo or product documents (for sale).',
  '5) укажите условия поставки, такие как:': '5) specify the terms of delivery such as:',
  '“Место отправки” (куда или откуда);': '“Place of departure” (where or from where);',
  '“Базис” согласно которому будет происходить поставка;': '“Basis” according to which the delivery will take place;',
  '“Тип отправки”, согласно тому, каким способом будет происходить поставка;': '“Dispatch type”, according to the way the delivery will take place;',
  '“Упаковка”, в зависимости от типа упаковки для поставки;': '“Package”, depending on the type of packaging for delivery;',
  '“Количество транспорта”, “Стандарт загрузки” и “Ограничение” (по необходимости). Обратите внимание, что для некоторых базисов и типов отправки не нужно предоставлять эту информацию. Эти поля используются для расчета объема продукции для отправки. Расчет производится по формуле: “Объем” = “Единиц транспорта” * “Стандарт загрузки”. Если присутствуют ограничения на максимальный объем загрузки, его необходимо указать в поле “Ограничение”. В таком случае, именно “Ограничение” будет использоваться в формуле вместо поля “Стандарт загрузки”;': '“Number of transport”, “Load standard” and “Limitation” (if necessary). Please note that for some bases and types of dispatch it is not necessary to provide this information. These fields are used to calculate the volume of products to ship. The calculation is made according to the formula: “Volume” = “Number of transport” * “Load standard”. If there are restrictions on the maximum download size, it must be specified in the “Limitation” field. In this case, the “Limitation” field will be used in the formula instead of the “Load Standard” field;',
  '“Условия оплаты”, в зависимости от предпочитаемого метода оплаты за продукцию;': '“Terms of payment”, depending on the preferred method of payment for the product;',
  '“Период отгрузки”, в зависимости от даты, когда вы ожидаете совершить отгрузку продукции (для покупки);': '“Shipment period”, depending on the date when you expect to make the shipment of the product (for purchase);',
  '6) заполните общую информацию:': '6) fill in general information:',
  '“Объем”, если он не был рассчитан или в случае когда рассчитанный объем отличается от фактического;': '“Value” if it has not been calculated or in the case when the calculated volume differs from the actual one;',
  '“Цена” за тонну продукции;': '“Price” per ton of product;',
  '“Валюта”, в зависимости от желаемой валюты для отображения цены;': '“Currency”, depending on the desired currency to display the price;',
  '7) укажите необходимые документы из списка, которые вы ожидаете получить вместе с продукцией от второй стороны (для покупки).': '7) indicate the necessary documents from the list that you expect to receive along with the products from the seller (for purchase).',
  '8) укажите дополнительные сведения:': '8) provide additional information:',
  '“Происхождение” — место производства или выращивания продукции (продажа).': '“Origin” - place of production or cultivation of products (sale).',
  '“Дата производства” (для продажи).': '“Manufacture date” (for sale)',
  '“Заявка действительная до”, где необходимо указать дату, до которой действует ваше предложение по продаже продукции. (для продажи).': '“Offer is valid until”, where you must indicate the date until which your offer to sell products is valid. (for sale)',
  '“Надпись на этикетке”, если продукция будет поставляться с этикеткой. Эту опцию можно включить/отключить по нажатию на переключатель.': '“Label inscription” if the product will be labeled. This option can be enabled / disabled by clicking on the switch.',
  '“Сведения по загрузке/выгрузке”, где вы можете указать дополнительную информацию которая касается продукции или поставки этой продукции.': '“Load/unload information”, where you can specify additional information that relates to the specifics of loading or unloading at the place of loading, in case of sale, and at the place of unloading, in case of purchase.',
  'Также, в зависимости от типа отправки, вы можете заполнить поля, которые отвечают за доставку по воде: “Количество бесплатных дней в порту”, “Не подходящие судоходные линии”, “Необходимое транзитное время (дней)”.': 'Also, in case products will be delivered in containers, you can specify “Number of free days in the port”, “Unsuitable shipping lines”, “Required transit time (days)”.',
  'После создания заявки она попадает в общий список заявок на странице “Заявки”. Также, вы можете найти свою заявку на странице “Мои заявки”, где отображаются заявки созданные вами.': 'After request creation, it is added to the general list of requests on the “Requests” page. Also, you can find your request on the page “My requests”, which displays requests you created.',
  'Профиль': 'Profile',
  'Торги': 'Trades',
  'На странице “Рынок” вы можете увидеть набор продуктов, которые доступны на платформе и краткую информацию о них. При нажатии на продукт, вы перейдете на страницу заявок с отфильтрованным списком заявок по этому продукту.': 'On the “Market” page you can see a list of products on a platform with available volumes of supply and demand for trading.By clicking on a specific product you will move to the requests page with a filtered list with this specific product requests.',
  'Также вы можете отфильтровать список продуктов по категории. Для этого выберите интересующую вас категорию в окне сверху. Список будет отфильтрован и вы увидите только те продукты, которые относятся к выбранной категории. Вы можете сбросить фильтр по нажатию на кнопку “Сбросить фильтры”.': 'Also you can filter the list of products by category. To do this just choose the category you are interested in at the dropdown above the list. Products will be filtered and you will see only those products that belong to the chosen category. You can reset filter by clicking on “Reset filters”.',
  'После создания ответной заявки или получении отклика на вашу заявку — создается торг в котором стороны могут урегулировать условия и совершить сделку. Все торги с вашим участием отображаются на странице “Мои торги”. Для удобного перемещения и отслеживания торгов вы можете использовать разные вкладки (открытые, полученные и т.д.) или использовать фильтр по тому же принципу что и на странице “Заявки”.Так как по одной заявке у вас могут быть несколько откликов одновременно, то торги по этой заявке будут проходить параллельно. Для перехода к торгам по конкретной заявке, нажмите на поле заявки на странице “Мои торги”.': 'After creating a response request or upon receiving a response to your request, an auction is created in which the parties can agree on the terms of delivery and conclude a deal. All trades with your participation are displayed on the page “My trades”. For convenient movement and tracking of trades, you can use different tabs (open, received, etc.) or use the filter according to the same principle as on the “Orders” page. Also, several trades can be held at the same time for one order, such trades will take place in parallel to each other. To go to trading on a specific request, click on the request field on the “My trades” page.',
  'При получении откликов на ваши заявки или при появлении новых сообщений в торгах — вы сможете увидеть соответствующие обозначения с количеством новых откликов в определенной вкладке или с количеством непрочитанных торгов в заявке.': 'When receiving responses to your trades or when new messages appear in the auction, you can see the corresponding designations with the number of new responses in a certain tab or with the number of unread auctions in the application.',
  'Для того, чтобы вы оперативно узнавали о:': 'In order for you to quickly learn about:',
  'новых заявках на платформе': 'new requests on the platform',
  'ответах на ваши заявки': 'responses to your requests',
  'действия в торгах с вашим участием': 'actions in trades with your participation',
  'На платформе существует система уведомлений. Вы можете увидеть значок с количеством новых уведомлений на иконке уведомлений. Также вы можете настроить получение уведомлений в настройках в разделе “Уведомления”. Там вы сможете отключить/включить уведомления.': 'There is a notification system on the platform. You can see a badge with the number of new notifications on the notification icon. You can also configure the receipt of notifications in the settings in the “Notifications” section. There you can disable / enable notifications.',
  'Для настройки получения уведомлений о новых заявках на платформе, администратор компании может выбрать по заявкам с какими продуктами он хочет получать уведомления. Например, при добавлении такого продукта как “Ячмень” в продуктовые группы, вы будете получать уведомления о добавлении на платформу новых заявок с продуктом “Ячмень”.': 'To configure the receipt of notifications about new requests on the platform, the company administrator can choose products in the settings he wants to receive notifications about. For example, when you add a product such as “Barley” to product groups, you will receive notifications when new requests for the product “Barley” are added to the platform.',
  'При клике на иконку уведомлений вы увидите выпадающее окно с несколькими новыми уведомлениями. Если вы хотите увидеть весь список полученных уведомлений — нажмите на кнопку “Показать все”. После этого вы перейдете на страницу уведомлений. Если хотите обнулить список непрочитанных уведомлений, то нажмите на кнопку “Прочитать все”. Также вы можете кликнуть на определенное уведомление для быстрого перехода к нужному событию.': 'When you click on the notification icon, you will see a drop-down window with several new notifications. If you want to see the entire list of received notifications, click on the “Show all” button. After that, you will be taken to the notification page. If you want to reset the list of unread notifications, then click on the “Read all” button. You can also click on a specific notification to quickly jump to the desired event.',
  'Если вы пропустили шаг заполнения информации во время регистрации, то вы всегда можете это сделать в настройках вашего профиля.Также в настройках вы можете изменить ваш пароль, настройки уведомлений и прочую информацию.': 'If you have skipped a step with entering your information after registration, you can always do this in the settings on your profile. Also, in the settings you can change your password, notification setting and other inform.',
  'Чтобы пригласить других пользователей представлять вашу компанию — вы можете пригласить их. Для этого в настройках, в разделе “Пользователи” вы можете скопировать ссылку и отправить её зарегистрированным пользователям у которых еще нет своей компании. Обратите внимание, что приглашать и управлять пользователями может только тот пользователь, кто зарегистрировал свою компанию — “Администратор” компании.': 'You can invite other users to place requests and participate in trades presenting your company. For this you should go to the settings, to the “Users” section and copy the link. After that you can send it to any registered user on the platform (who doesn’t have company yet)  so the other user can review and accept your invitation. Please, pay attention that only company “Administrator” can send invitations, manage users inside the company and change company information.',
  'На странице заявок вы можете увидеть список всех предложений по покупке или продаже созданные пользователями на платформе. Также в списке вы можете увидеть краткую информацию о продукте и способе отправки.': 'On the requests page, you can see a list of all buy or sell offers created by users on the platform. Also in the list you can see brief information about the product and the method of shipping.',
  'Для удобного и быстрого поиска, вы можете воспользоваться доступными фильтрами. Просто укажите конкретные параметры, по которым вы хотите отфильтровать список заявок и нажмите “Применить фильтр”. Тогда список заявок, который будет отображаться на этой странице будет соответствовать введенным критериям и вы быстро найдете интересующее вас предложение.': 'For a convenient and quick search, you can use the available filters. Just specify the specific parameters by which you want to filter the list of requests and click “Apply filter”. Then the list of requests that will be displayed on this page will match entered criteria, so you can quickly find the offer you are interested in.',
  'Более того, вы можете воспользоваться полем поиска, для фильтрации заявок по ключевым словам. Для этого необходимо ввести ключевое слово в поле поиска и вы увидите окно с несколькими наиболее релевантными заявками, которые соответствуют вашему поиску. Для просмотра всего списка заявок по вашему поисковому запросу, нажмите на кнопку “Смотреть все” и список заявок будет отфильтрован по ключевому слову, которое вы ввели.': 'In addition, you can use the search box to filter requests by keywords. To do this, you must enter a keyword in the search field and you will see a window with several of the most relevant requests that match your search. To view the entire list of requests for your search query, click on the “View all” button and the list of requests will be filtered by the keyword that you entered. Also, if you know the request number, you can enter it in the search field and it will immediately be displayed.',
  'Чтобы не потерять интересующие вас заявки и иметь возможность посмотреть или ответить на них позже, вы можете сохранить их в избранное. Для этого необходимо нажать на иконку ☆ на интересующей вас заявке и вы увидите как она окрасилась в другой цвет ★. Также вы увидите уведомление о том, что заявка была добавлена в избранное. Для удаления заявки из избранного нужно повторить то же действие и вы увидите соответствующее уведомление об удалении заявки из избранного. Для просмотра списка избранного, нажмите на кнопку ☆ вверху страницы. В этом списке вы сможете отслеживать заявки которые вы сохранили.': 'In order to save the requests you are interested in and to be able to view or respond to them later, you can save them to your favorites. To do this, you need to click on the ☆ icon on the request you are interested in and you will see how it turned into a different color ★. You will also see a notification that the request has been added to favorites. To remove the request from the favorites, you need to repeat the same action and you will see a corresponding notification about the removal of the request from the favorites. To view the list of favorites, click on the ☆ button at the top of the page. In this list, you will be able to track the requests that you have saved.',
  'Если вы заинтересовались какой-либо конкретной заявкой, то вы можете перейти к ее детальному описанию при клике на поле с этой заявкой. Если заявка вам подходит и вы хотите сообщить владельцу заявки о вашем желании заключить с ним сделку — нажмите на кнопку “Сделать предложение” или ”Создать запрос”.': 'If you are interested in any specific request, then you can go to its detailed description by clicking on the field with this request. If the request suits you and you want to inform the owner of the request about your desire to conclude a deal with him - click on the “Make offer” or “Make request” button.',
  'После нажатия на кнопку вы увидите страницу создания заявки (ответной заявки). На этой странице большинство полей будет заполнено информацией из исходной заявки. Вам необходимо заполнить оставшиеся пустыми поля (по необходимости) или вы можете изменить какие-либо условия заявки, которые вам не подходят (например, изменить условия оплаты).': 'After clicking on the button, you will see the page for creating a request (response request). On this page, most of the fields will be filled in with information from the original application. You need to fill in the remaining blank fields (for example, delivery time) or you can change any conditions of the request that do not suit you (for example, change the terms of payment).',
  'При создании такой (ответной) заявки, вы выражаете свое намерение на покупку/продажу продукции по указанным вами условиям. Созданная таким образом заявка будет также размещаться в общем списке заявок на странице “Заявки” и будет доступна для просмотра/отклика другим пользователям платформы.': 'When creating such a (response) request, you express your intention to buy / sell products according to the conditions you specified. The request created in this way will also be placed in the general list of applications on the “Requests” page and will be available for viewing / response to other users of the platform.',
  'После создания ответной заявки, вторая сторона получит уведомление о том, что вы откликнулись на его заявку. Вы переместитесь на страницу торгов, где сможете вместе со второй стороной урегулировать окончательные условия и заключить сделку.': 'After creating a response request, the second party will receive a notification that you have responded to his request. You will be taken to the trades page, where you can, together with the other party, settle the final conditions and conclude a deal.',
  'Чтобы зарегистрироваться — необходимо перейти на страницу ': 'To register — you have to move to the ',
  'регистрации,': 'registration',
  ' выбрать роль, ввести адрес электронной почты и пароль.': ' page, choose your role, enter email address and password.',
  'После этого, необходимо активировать вашу электронную почту по нажатию на ссылку в письме, которое вам придет на указанный адрес.': 'After that, you have to verificate your email address by clicking on the link in your email, that will be sent to the entered email address.',
  'Для получения доступа к полному функционалу платформы - необходимо также предоставить контактную информацию, которая будет использоваться при заключении сделки для связи со второй стороной торгов.': 'To get full access to the platform, you have to provide contact details that will be used during the deal confirmation to get in touch with the person you are having a deal.',
  'Чтобы получить возможность создавать заявки на платформе — необходимо зарегистрировать компанию, которую вы будете представлять, или присоединиться к существующей компании на платформе, в случае когда представитель вашей компании уже зарегистрирован на платформе. Для этого, введите название компании и заполните остальные поля формы согласно вашим интересам и виду деятельности. Администратор платформы проверит и подтвердит регистрацию вашей компании в ближайшее время после отправки формы.': 'To create requests on the platform, you should register a company you present, or join an existing one, in case if someone from your company has already registered on the platform. To create a company, enter its name and provide all the necessary info according to your interest. Platform Administrator will review your application and confirm your company registration at the nearest time.',
  'Для урегулирования вопросов по сделке и заключения договора, вам необходимо воспользоваться функционалом торгов. Когда вы откликнетесь на заявку или когда другой пользователь откликнется на вашу заявку — создается торг.': 'To agree on the terms of delivery and conclude a deal, you will be offered the trading functionality. Trades are created if you make a counter offer or someone responds to your application.',
  'Внутри торга вы можете увидеть всю необходимую информацию по нему:': 'Inside the auction, you can see all the necessary information on it:',
  'информацию о исходной заявке (в левом верхнем углу);': 'information about the original application (in the upper left corner);',
  'информацию о второй стороне торгов (слева). Также слева отображается список всех торгов по заявке;': 'information about the second side of the trade (left). The list of all trades for the order is also displayed on the left;',
  'информацию об ответной заявке (справа);': 'information about the response request (right);',
  'историю изменений и предложений по сделке в ходе торгов (по центру).': 'history of changes and proposals for a deal during trading (center).',
  'Также в торге вы можете совершать различные действия для урегулирования условий сделки:': 'In trading, you have the opportunity to choose the following options for making a deal:',
  '“Предложить цену”, где вы можете предложить цену отличную от цены в изначальной заявке и установить время, в течении которого ваше предложение будет иметь силу. По истечении этого времени — торг будет закрыт;': '“Offer price” - where you can offer your price for the order placed on the right, as well as indicate the validity period of your price. After the expiration date, the trades will be closed automatically;',
  '“Редактировать заявку”, где вы можете быстро отредактировать условия заявки в рамках конкретного торга. Ваши изменения будут отображены отдельным сообщением в чате;': '“Edit order”, where you can quickly edit the conditions of the order within a particular trade. Your changes will be reflected in a separate chat message;',
  '“Принять предложение”, если все условия сделки вам подходят, то вы можете согласиться с ними и ожидать согласия второй стороны. После согласия обеих сторон — сделка считается успешно завершенной и на почту обеим сторонам будет выслано письмо с окончательной информацией по сделке и контактными данными;': '“Accept offer”, if all the terms of the transaction suit you, then you can agree with them and expect the consent of the other party. After the consent of both parties - the transaction is considered to be successfully completed and a letter with the final information on the transaction and contact details will be sent to both parties;',
  '“Выйти из торгов”, если торг вам более не интересен, то вы можете покинуть его, указав причину закрытия торга. После этого дальнейшие действия внутри этого торга будут недоступны.': '“Leave trade”, if you are no longer interested in trading, you can leave it, indicating the reason for closing the trading. After that, further actions within this bargain will be unavailable.',
  'После совершения действия внутри торга по какой-либо заявке, вам необходимо дождаться ответа от второй стороны. До того, как вторая сторона ответит на ваше предложение — вы не можете соглашаться на параллельные торги или делать другие действия, так как это может привести к дублированию сделок по одной и той же заявке, но в параллельных торгах.': 'After performing an action within the trade on any order, you need to wait for a response from the second party. Before the second party responds to your offer, you cannot agree to parallel trades or do other actions, as this may lead to duplication of transactions for the same order, but in parallel trades.',
  'Пройти обучение': 'Complete tutorial',
  'Не найдено': 'Not found',
  'Продуктов нет': 'There are no products',
  'Заявок еще нет': 'There is no requests yet',
  'Страница не найдена': 'Link is inactive',
  'Ой, похоже, мы не можем найти страницу, которую вы ищете. Попробуйте вернуться на предыдущую страницу или посетите наш Справочный центр для получения дополнительной информации.': 'Oops, it looks like we cannot find the page you are looking for. Please, try to return to the previous page or visit our Help Center for more information.',
  'Перейти на главную страницу': 'Go to the homepage',
  'Новые': 'New',
  'Последние 7 дней': 'Last 7 days',
  'с': 's',
  'м': 'm',
  'ч': 'hrs',
  'д': 'd',
  'мес': 'mo',
  'г': 'y',
  'Локация': 'Location',
  'Осталось ': 'Left ',
  'Редактировать': 'Edit',
  'Фото продукта': 'Photo of product',
  'Действителен до': 'Valid until',
  'Заявка ': 'Request ',
  'Вы действительно хотите удалить заявку ': 'Are you sure you want to delete request ',
  'Удалить': 'Delete',
  'Удалить лот': 'Delete lot',
  'Создать запрос': 'Make request',
  'Удалить запрос': 'Delete request',
  'Сделать предложение': 'Make offer',
  'Заявок по этому продукту еще нет': 'There are no requests for this product',
  'Добавлено': 'Added',
  'Этот продукт уже добавлен в список': 'This product has been added to the list already',
  'Добавить': 'Add',
  'Удалено': 'Deleted',
  'Компания ждет подтверждения администратором платформы. После апрува Все поля будут заполнены данными': 'Company is waiting for confirmation from Administrator. After his approval, all fields will be filled out',
  'Сохранено': 'Saved',
  'О Компании': 'About company',
  'Для получения только актуальных предложений обновите данные по вашей Компании': 'Update your Company information for receiving relevant offers',
  'Компания {{companyName}} ждёт подтверждения администратором': 'The company {{companyName}} is waiting for approval by the administrator',
  'Основные настройки': 'Main settings',
  'Безопасность': 'Security',
  'Пользователи': 'Users',
  'Язык': 'Language',
  'Выберите тип уведомлений, которые вы хотите получать': 'Choose type of notifications you would like to receive',
  'Рассылка': 'Mailing',
  'Вы будете получать уведомления, которые соответствуют заполненным вами продуктам и рынкам. Если вы хотите поменять типы уведомлений, которые вы хотите получать, перейдите в меню “О Компании” и выберите интересующие вас продукты и рынки.': 'You will receive notifications that match chosen products and markets. If you want to change notifications types, that will be sent to you, go to "About company" and choose products and markets you are interested in.',
  'тип уведомления': 'notifications type',
  'уведомления о новых заявках': 'notifications about new requests',
  'уведомления о текущих торгах': 'notifications about current trades',
  'Введите старый пароль': 'Enter your old password',
  'Ваш пароль был успешно изменен': 'Your password has been successfully changed',
  'Старый пароль введен неверно': 'Old password is wrong',
  'Пароль должен отличаться от e-mail': 'Password should be different to your email',
  'Слишком простой пароль': 'Too simple password',
  'Ссылка для восстановления пароля была отправлена на почту': 'The password recovery link has been sent to your email address',
  'Для изменения пароля введите в верхнем поле старый пароль затем в нижнем поле новый.': 'Enter old and new password to the appropriate fields to change your password.',
  'Старый пароль': 'Old password',
  'Изменить': 'Change',
  'Забыли старый пароль?': 'Forgot old password?',
  'В целях безопасности ваших данных мы настоятельно рекомендуем вам периодически менять пароль. Если вы столкнулись с проблемой входа в систему просим вас связаться с администратором е-мейл:': 'For your data security we strongly recommend you to change your password periodically. If you encounter a problem with logging into the system, please contact the administrator by e-mail:',
  'Восстановить': 'Recover',
  'Для удобства пользователей системы платформа предоставляет множественный доступ к Компании': 'Multiple access to the Company provides for the convenience of platform users',
  'Все пользователи': 'All users',
  'без имени': 'noname',
  'В компании еще нет пользователей': 'Company doesn\'t have users yet',
  'Пригласить': 'Invite',
  'Копировать ссылку': 'Copy link',
  'Ваш список торгов пуст.': 'You list of trades is empty.',
  'Для начала торгов необходимо': 'To start a trade you should',
  'создать заявку': 'create request',
  'или начать': 'or start',
  'торг на рынке': 'trade on the market',
  'Загрузить фото': 'Add foto',
  'Формат файла': 'File format',
  'Компактный режим': 'Compact view',
  'Приглашения': 'Invites',
  'Подписка': 'Subscription',
  'Приглашение было отправлено на указанную почту': 'The invitation was sent to the specified mail',
  'История приглашений': 'Invitation history',
  'Количество символов не может быть меньше {{count}}': 'The number of characters cannot be less than {{count}}',
  'Статус': 'Status',
  'Дейстует': 'Active',
  'Действия': 'Actions',
  'ОК': 'OK',
  'истёкший': 'expired',
  'Перейти': 'View',
  'Заполните информацию о компании, что бы бесплатно публиковать ваши заявки и получать нотификации с релевантными заявками от других компаний': 'Fill in information about the company in order to publish your requests for free and receive notifications with relevant applications from other companies',
  'additional_info': 'Additional information',
  'Public': 'Public',
  'Complaint.textWithOurEmail': 'You can also use our e-mail to get in touch',
  'SideBar.About.name': 'About us',
  'Auth.Register.landingPage.subtitle': 'Register to trade on Glendeal platform, just in 2 minutes',
  'Auth.Register.quick.attention': 'Passwordless registration with support for authorization via a quick link',
  'EmailConfirmationInfo.landingPage.text': 'Please, confirm email address to proceed to the last step of registration to unlock trading on Glendeal',
  'infoPopup.errors.wrongToken': 'Invalid link',
  'infoPopup.errors.wrongGoogleToken': 'Currently you can\'t login this way',
};
