export const uk = {
  needSubscription: 'Ця функція потребує наявності підписки',
};

export const en: typeof uk = {
  needSubscription: 'This feature needs a subscription',
};

export const ru: typeof uk = {
  needSubscription: 'Эта функция нуждается в наличии подписки',
};
