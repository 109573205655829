import moment from 'moment';
import {
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  LOAD_MORE_NOTIFICATIONS,
  GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS,
  CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT,
} from 'actions/notificationsActions';
import { FORMAT_DATES } from 'constants/general';

const initialState = {
  page: 0,
  list: [],
  count: null,
  next: null,
  previous: null,
  loading: false,
  error: null,
  unread_notifications: 0,
  unread_trade_message: 0,
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS: {
      const currentDate = moment(new Date()).format(FORMAT_DATES.main);

      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload,

        noticesToday: action.payload.list.filter(
          ({ created_at }) => moment(created_at).format(FORMAT_DATES.main) === currentDate,
        ),

        noticesOthers: action.payload.list.filter(
          ({ created_at }) => moment(created_at).format(FORMAT_DATES.main) !== currentDate,
        ),
      };
    }
    case LOAD_MORE_NOTIFICATIONS:
      return {
        ...state,
        page: action.payload.page,
        loading: false,
        error: null,
        next: action.payload.next,
        list: [...state.list, ...action.payload.list],
        count: action.payload.count,
        noticesToday: (() => {
          const currentDate = moment(new Date());
          return [...state.list, ...action.payload.list].filter(
            ({ created_at }) => moment(created_at).format(FORMAT_DATES.main) === currentDate.format(FORMAT_DATES.main),
          );
        })(),
        noticesOthers: (() => {
          const currentDate = moment(new Date());
          return [...state.list, ...action.payload.list].filter(
            ({ created_at }) => currentDate.format(FORMAT_DATES.main) !== moment(created_at).format(FORMAT_DATES.main),
          );
        })(),
      };
    case GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT:
      return {
        ...state,
        unread_trade_message: action.payload.absolute
          ? action.payload.absolute
          : Math.max(0, state.unread_trade_message + (action.payload.increment || 0)),
      };
    default:
      return state;
  }
}
