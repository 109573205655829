import store from 'store/store';
import Nav, { ROUTES } from 'nav';
import { ITrade } from 'reducers/slices/trades.slice';
import { IBasis, ICategory, ICountry, ICulture, ICurrency, IPackaging, IShipping } from 'reducers/allReducer';
import { TradeStatus } from 'constants/general';
import { DateTimeHelper, FormatTypes } from 'core/helpers/dateTime.helper';

export type { ITrade };

export enum InitiatorTypeEnum {
  opened = 'opened',
  got = 'got',
}

export class Trade {
  private _store!: ReturnType<typeof store['getState']>;
  constructor (
    public $: ITrade,
  ) {}

  get id() { return this.$.id; }

  goToPage(replace = false): void {
    const { type, id } = this.myLot;
    const path = `${ROUTES.chat}?type=${type}&id=${id}&tradeId=${this.$.id}`;
    Nav.go(path, { replace, state: { trade: this } });
  }

  validUntil(formatIndex = 0): string {
    return DateTimeHelper.format(this.$.valid_until, formatIndex, FormatTypes.dates);
  }

  get basis(): IBasis {
    return this.store.all.basis.list[this.$.basis_id];
  }
  get category(): ICategory {
    return this.store.all.categories.list[this.culture.category_id];
  }
  get culture(): ICulture {
    return this.store.all.cultures.list[this.$.offer.culture_id];
  }
  get currency(): ICurrency {
    return this.store.all.currencies.list[this.$.currency_id];
  }
  get origin(): ICountry {
    return this.store.all.origins.list[this.$.offer.origin_id];
  }
  get packaging(): IPackaging {
    return this.store.all.packaging_options.list[this.$.packaging_id];
  }
  get shipping(): IShipping {
    return this.store.all.shipping_options.list[this.$.shipping_id];
  }
  get myLot(): ITrade['offer'] {
    return this.$.offer.is_my ? this.$.offer : this.$.request;
  }
  get oppositeLote(): ITrade['request'] {
    return this.$.offer.is_my ? this.$.request : this.$.offer;
  }
  get companyName(): string {
    return this.oppositeLote.company_name || '';
  }
  get openType(): InitiatorTypeEnum {
    return this.$[this.$.initiator].is_my ? InitiatorTypeEnum.opened : InitiatorTypeEnum.got;
  }
  get createdAt(): string {
    return DateTimeHelper.format(this.$.created_at, 0, FormatTypes.dates);
  }


  get isCompany(): boolean {
    return !!this.oppositeLote.company_name;
  }
  get isConfirming(): boolean {
    return this.$.status === TradeStatus.confirming;
  }
  get hasMyConfirming(): boolean {
    return this.$.offer.is_my ? this.$.seller_confirmation : this.$.buyer_confirmation;
  }
  get isValid(): boolean {
    return DateTimeHelper.isFuture(this.$.valid_until);
  }

  private get store() {
    return this._store ? this._store : this._store = store.getState();
  }
}
