export const SCOPE_CARD_SECTION = 'CardSection';

export const uk = {
  left: 'Залишилось',
  closed: 'Закрита',
  by_lot: 'По Заявці',
  buttons: {
    similar: 'Подібні заявки',
    opposite: 'Зустрічні заявки',
  },
};

export const en: typeof uk = {
  left: 'Left',
  closed: 'Closed',
  by_lot: 'By lot',
  buttons: {
    similar: 'Similar lots',
    opposite: 'Opposit lots',
  },
};

export const ru: typeof uk = {
  left: 'Осталось',
  closed: 'Закрыта',
  by_lot: 'По Заявке',
  buttons: {
    similar: 'Подобные заявки',
    opposite: 'Встречные заявки',
  },
};
