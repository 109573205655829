import { T } from 'hooks';
import { TFunction } from 'i18next';

const SIDE_BAR_WIDTH = 240;

const FILTERS_MODELS = [
  'Basis',
  'Category',
  'Company',
  // 'Country',
  'Culture',
  'Origin',
  'PortsOption',
  'PackagingOption',
] as const;

export enum LotType {
  offer = 'offer',
  request = 'request',
}
export enum TradeStatus {
  open = 'open',
  confirming = 'confirming',
  confirmed = 'confirmed',
  closed = 'closed',
}

const TYPES_MARKET = {
  offer: 'offer',
  request: 'request',
};

const TYPES_MESSAGES = {
  confirmation: 'confirmation',
  trade_closed: 'trade_closed',
  bid_changed: 'bid_changed',
  proposal_changed: 'proposal_changed',
  is_allowed_action: 'is_allowed_action',
  trade_start: 'trade_start',
  message: 'message',
  error: 'error',
};

const API_ROUTES = {
  activation: {
    post: '/users/activation/',
    resend: '/users/activation/resend/',
    check: '/users/auth/short-link-verification/',
  },
  authMe: '/users/auth/me/',
  cultures: '/cultures/',
  dashboard: {
    offer: '/offers/dashboard/',
    request: '/requests/dashboard/',
  },
  lotOveralls: {
    offer: '/offers/overalls//',
    request: '/requests/overalls/',
  },
  markets: '/markets',
  notifications: '/notifications/',
  offers: '/offers/',
  offersTrades: '/offers/trades/',
  offersFavorites: '/offers/favorites/',
  readNotifications: '/notifications/read/',
  requests: 'requests/',
  requestsFavorites: '/requests/favorites/',
  requestsTrades: '/requests/trades/',
  settings: {
    addresses: '/addresses/',
  },
  shippingDocuments: '/shipping_documents/',
  trades: {
    root: '/trades/',
    infoBadges: '/trades/info_badges/',
  },
  userDashboard: {
    request: '/requests/user_dashboard/',
    offer: '/offers/user_dashboard/',
  },
  users: {
    root: '/users/',
    tokens: {
      phoneVerify: '/users/tokens/phone-verify/',
    },
  },
  userRequests: '/user/requests/',
  userOffers: 'user/offers/',
};

const PAGES = {
  market: 'market',
  proposals: 'proposals',
  trades: 'trades',
  favorites: 'favorites',
};

const DAYS_NAMES = (_t: TFunction): string[] => [_t('день'), _t('дня'), _t('дней')];
const HOURS_NAMES = (_t: TFunction): string[] => [_t('час'), _t('часа'), _t('часов')];
const MINUTES_NAMES = (_t: TFunction): string[] => [_t('минута'), _t('минуты'), _t('минут')];
const TRADES_NAMES = (_t: T): string[] => [_t('торг'), _t('торга'), _t('торгов')];
const REQUESTS_NAMES = (_t: TFunction): string[] => [_t('запрос'), _t('запроса'), _t('запросов')];
const OFFERS_NAMES = (_t: TFunction): string[] => [_t('лот'), _t('лота'), _t('лотов')];

const NOTIFICATIONS_TYPE = {
  attention: 'attention',
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

const REQUESTS_TYPES = {
  offer: 'offer',
  request: 'request',
  trade: 'trade',
};

const FORMAT_DATES = {
  dateOfManufacture: 'YYYY',
  main: 'DD/MM/YYYY',
  dayMonth: 'DD/MM',
  mainForBack: 'YYYY-MM-DD',
  timeWithLabel: 'LT',
  time24: 'HH:mm',
  simpleDatetime: 'DD/MM HH:mm',
  full: 'DD/MM/YYYY HH:mm:ss',
};

const IMAGES_PREVIEW = {
  smallPreviews: 4,
};

const STATUS_CODES = {
  ok: 200,
  alredyReported: 208,
  badRequest: 400,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  emptyPage: 410,
  precondition: 428,
};


const STEP_ACTIONS = {
  next: 'next',
  prev: 'prev',
};

const NOTIFICATIONS_WIDGET = {
  mobile: 4,
  desktop: 5,
};

export enum UserStatusEnum {
  User = 'user',
  Admin = 'admin',
  UserWithoutCompany = 'newAdmin',
}

const VALIDATION_VALUES = {
  oneHundred: 100,
  twoHundred: 200,
  tenThousand: 10000,
  maximumIntegerValue: 32767,
  lessMillion: 999999,
};


export {
  SIDE_BAR_WIDTH,
  TYPES_MARKET,
  TYPES_MESSAGES,
  API_ROUTES,
  DAYS_NAMES,
  HOURS_NAMES,
  MINUTES_NAMES,
  TRADES_NAMES,
  REQUESTS_NAMES,
  OFFERS_NAMES,
  PAGES,
  NOTIFICATIONS_TYPE,
  REQUESTS_TYPES,
  FORMAT_DATES,
  IMAGES_PREVIEW,
  STATUS_CODES,
  NOTIFICATIONS_WIDGET,
  STEP_ACTIONS,
  FILTERS_MODELS,
  VALIDATION_VALUES,
};
