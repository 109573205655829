import { SCOPE_LOT } from 'i18n/_single/Lot';

export const SCOPE_FORM_COMPANY_SCOPE = 'FormCompany';
export const SCOPE_FORM_LOT = 'FormLot';

export const uk = {
  errors: require('./errors').uk,
  [SCOPE_FORM_COMPANY_SCOPE]: {
    AutocompleteField: {
      country: {
        title: 'Країна розміщення',
        label: 'Країна',
        placeholder: 'Країна розміщення',
      },
    },
    SimpleTextField: {
      address: {
        label: 'Адреса',
        placeholder: 'Адреса компанії',
      },
      local_name: {
        label: 'Локальна назва',
        placeholder: 'Локальна назва компанії',
      },
    },
    buttons: {
      creationComplete: 'Надіслати на верифікацію',
    },
  },
  [SCOPE_FORM_LOT]: {
    price: {
      placeholder: `$t(${SCOPE_LOT}$zeroPrice)`,
    },
    parameters: {
      tooltips: {
        not_default_value: 'Цей параметр має значення, відмінне від стандартного {{defaultValue}}{{units}}',
        not_empty_as_default_value: 'Цей параметр не має стандартного значення',
      },
    },
  },
  remove_all: 'Видалити всі',
};

export const en: typeof uk = {
  errors: require('./errors').en,
  [SCOPE_FORM_COMPANY_SCOPE]: {
    AutocompleteField: {
      country: {
        title: 'Host country',
        label: 'Country',
        placeholder: 'Host country',
      },
    },
    SimpleTextField: {
      address: {
        label: 'Address',
        placeholder: 'Company address',
      },
      local_name: {
        label: 'Local name',
        placeholder: 'Local company name',
      },
    },
    buttons: {
      creationComplete: 'Send to verification',
    },
  },
  [SCOPE_FORM_LOT]: {
    price: {
      placeholder: `$t(${SCOPE_LOT}$zeroPrice)`,
    },
    parameters: {
      tooltips: {
        not_default_value: 'This parameter has a value other than the default {{defaultValue}}{{units}}',
        not_empty_as_default_value: 'This parameter has no default value',
      },
    },
  },
  remove_all: 'Remove all',
};

export const ru: typeof uk = {
  errors: require('./errors').ru,
  [SCOPE_FORM_COMPANY_SCOPE]: {
    AutocompleteField: {
      country: {
        title: 'Страна размещения',
        label: 'Страна',
        placeholder: 'Страна размещения',
      },
    },
    SimpleTextField: {
      address: {
        label: 'Адрес',
        placeholder: 'Адрес компании',
      },
      local_name: {
        label: 'Локальное название',
        placeholder: 'Локальное название компании',
      },
    },
    buttons: {
      creationComplete: 'Отправить на верификацию',
    },
  },
  [SCOPE_FORM_LOT]: {
    price: {
      placeholder: `$t(${SCOPE_LOT}$zeroPrice)`,
    },
    parameters: {
      tooltips: {
        not_default_value: 'Данный параметр имеет значение отличное от стандартного {{defaultValue}}{{units}}',
        not_empty_as_default_value: 'Данный параметр не имеет стандартного значения',
      },
    },
  },
  remove_all: 'Удалить все',
};
