import { useState, useEffect } from 'react';

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

export const useFnDebounce = (fn, delay) => {
  const [handler, setHandler] = useState(null);

  const debouncedFn = (...arg) => setHandler(setTimeout(() => fn(...arg), delay)); // CHECK working setTimeout parameters
  useEffect(() => () => clearTimeout(handler), [handler]);

  return [fn, debouncedFn];
};
