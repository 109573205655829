import React, { ReactNode } from 'react';
import { Dialog, Box, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useT } from 'hooks';
import { SaveButton } from '../#CustomComponents';
import './index.scss';

interface Props {
  show: boolean;
  children: ReactNode;
  onClose: () => void;
}

export const Alert: React.FC<Props> = ({ show, children, onClose }) => {
  const _t = useT();

  if (!show) return null;
  return (
    <Dialog
      className="dialog-alert"
      onClose={onClose}
      open={Boolean(show)}
      transitionDuration={{ enter: 200, exit: 0 }}
    >
      <Box p={4}>
        <IconButton className="close" component="span" size="small" onClick={onClose} >
          <Close/>
        </IconButton>
        {children}
        <Box mt={3}>
          <Box className="dialog-alert__btns" textAlign="center">
            <SaveButton onClick={onClose}>{_t('ОК')}</SaveButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
