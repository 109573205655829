export const SCOPE_SETTINGS = 'Settings';

const LOCAL_NAMESPACES = [
  'Addresses',
  'notifications',
  'Subscription',
  'User',
];

const LANGUAGES = ['en', 'uk', 'ru'];

const uk = {
  title: 'Налаштування',
  tabs: {
    company: 'Компанія',
    addresses: 'Адреси',
    user: 'Контакт',
    notifications: 'Повідомлення',
    security: 'Безпека',
    users: 'Користувачі',
    invites: 'Запрошення',
    subscription: 'Підписка',
  },
};
const en: typeof uk = {
  title: 'Settings',
  tabs: {
    company: 'Company',
    addresses: 'Addresses',
    user: 'Contact',
    notifications: 'Notifications',
    security: 'Security',
    users: 'Users',
    invites: 'Invites',
    subscription: 'Subscription',
  },
};
const ru: typeof uk = {
  title: 'Настройки',
  tabs: {
    company: 'Компания',
    addresses: 'Адреса',
    user: 'Контакт',
    notifications: 'Уведомления',
    security: 'Безопасность',
    users: 'Пользователи',
    invites: 'Приглашения',
    subscription: 'Подписка',
  },
};


const exporting: Record<typeof LANGUAGES[number], typeof uk & Record<string, {}>> = { en, uk, ru };

LANGUAGES.forEach(language => {
  // if (!exporting[language]) exporting[language] = {};
  LOCAL_NAMESPACES.forEach(lns =>
    exporting[language][lns] = require(`./${lns}`)[language] || require(`./${lns}`).default[language],
  );
});

export default exporting;
