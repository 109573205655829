export const uk = {
  title: 'Повідомлення',
  tip: 'Виберіть тип повідомлень, які ви хочете отримувати',
  infoTitle: 'Розсилка',
  infoText: 'Ви будете отримувати повідомлення, які відповідають заповненим вами продуктам і ринкам. Якщо ви хочете поміняти типи повідомлень, які ви хочете отримувати, перейдіть в меню “Про Компанію” і виберіть ваші продукти і ринки.',
  notificationType: 'Тип повідомлення',
  fields: {
    new_proposal_notifications: 'Повідомлення про нові заявки',
    trades_notifications: 'Повідомлення про поточні торги',
    my_company_notifications: 'Повідомлення про зміни по компанії',
    about_soon_notifications: 'Повідомлення про закінчення терміну заявки',
  },
  save: 'Зберегти',
  infoPopup: {
    success: 'Збережено',
    error: 'Не вийшло зберегти настройки',
  },
};

export const ru: typeof uk = {
  title: 'Уведомления',
  tip: 'Выберите тип уведомлений, которые вы хотите получать',
  infoTitle: 'Рассылка',
  infoText: 'Вы будете получать уведомления, которые соответствуют заполненным вами продуктам и рынкам. Если вы хотите поменять типы уведомлений, которые вы хотите получать, перейдите в меню “О Компании” и выберите интересующие вас продукты и рынки.',
  notificationType: 'Тип уведомления',
  fields: {
    new_proposal_notifications: 'Уведомления о новых заявках',
    trades_notifications: 'Уведомления о текущих торгах',
    my_company_notifications: 'Уведомления об изменениях по компании',
    about_soon_notifications: 'Уведомление об истечении срока заявки',
  },
  save: 'Сохранить',
  infoPopup: {
    success: 'Сохранено',
    error: 'Не получилось сохранить настройки',
  },
};

export const en: typeof uk = {
  title: 'Notifications',
  tip: 'Choose type of notifications you would like to receive',
  infoTitle: 'Mailing',
  infoText: 'You will receive notifications that match chosen products and markets. If you want to change notifications types, that will be sent to you, go to "About company" and choose products and markets you are interested in.',
  notificationType: 'Notifications type',
  fields: {
    new_proposal_notifications: 'Notifications about new requests',
    trades_notifications: 'Notifications about current trades',
    my_company_notifications: 'Notifications about the company',
    about_soon_notifications: 'Notifications about upcoming lot closing',
  },
  save: 'Save',
  infoPopup: {
    success: 'Success',
    error: 'Error',
  },
};
