import {
  GET_FAVORITES_SUCCESS,
  SET_LOADING,
  SET_APPENDING,
  DELETE_FROM_FAVORITES,
} from 'actions/favoritesActions';
import Factory, { IListState } from './utils/FactoryLots';

interface IFavorite {
  id: number;
  created_at: string;
}

interface ActionSuccses {
  type: typeof GET_FAVORITES_SUCCESS;
  payload: IListState<IFavorite>;
}
interface ActionLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}
interface ActionAppending {
  type: typeof SET_APPENDING;
  appending: boolean;
}
interface ActionDelete {
  type: typeof DELETE_FROM_FAVORITES;
  payload: number;
}

type Action = ActionSuccses | ActionLoading | ActionAppending | ActionDelete;

const initialState = Factory.initialState<IFavorite>();

export default function favorites(state = initialState, action: Action): IListState<IFavorite> {
  switch (action.type) {
    case GET_FAVORITES_SUCCESS: return Factory.getSuccess(state, action);
    case SET_LOADING: return Factory.setLoading(state, action);
    case SET_APPENDING: return Factory.setAppending(state, action);
    case DELETE_FROM_FAVORITES:
      return {
        ...state,
        list: state.list.filter(el => el.id !== action.payload),
        count: state.count - 1,
      };
    default:
      return state;
  }
}
