import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AnyTODO } from 'core/interfaces/AnyTODO.type';
import { RootState } from 'store/store.types';
import { API, API_ROUTES } from 'modules/Api';
import { toApiQuery } from 'api/utils';
import { factoryInitListState, FactoryListBuilder } from './helpers';
import { IApiListResponse, IInitListState } from './interfaces';
import { Nullable } from 'core/interfaces/common.interfaces';

export { useTsSelector, useTsDispatch } from 'store/store.types';

export interface IAddress {
  id: number;
  name: string;
  company_id?: number;
  owner_id?: number;
  index?: string;
  country_id: number;
  port_id?: number | null;
  state_id?: number | null;
  state_sum?: Nullable<string>;
  state_name?: string | null;
  district_id?: number | null;
  district_sum?: Nullable<string>;
  district_name?: string | null;
  city: string;
  station?: string | null;
  details?: string | null;
  archived_at: string | null;
  created_at: string;
  updated_at?: string;
  sort: number;
}


const initialState = factoryInitListState<IAddress>();

export const NAME = 'addresses';
const parent = 'global';
const scope = `${parent}/${NAME}`;
const apiUrlBase: string = API_ROUTES.settings.addresses;

export const getList = createAsyncThunk(
  `${scope}/getList`,
  async (payload: { params: Record<string, AnyTODO>, append?: boolean, force?: boolean }, thunkApi): Promise<IApiListResponse<IAddress> | null> => {
    const { next, loaded } = (thunkApi.getState() as RootState).addresses;
    if (!payload.force && loaded) {
      return null;
    }
    const apiUrl = payload.append && next ? next : `${apiUrlBase}?${toApiQuery(payload.params)}`;
    return API.get<IApiListResponse<IAddress>>(apiUrl).then(res => res.data);
  },
);


export const createAddress = createAsyncThunk(
  `${scope}/create`,
  async (payload: Partial<IAddress>, { rejectWithValue }): Promise<Partial<IAddress>> => {
    try {
      const { data } = await API.post<Partial<IAddress>>(apiUrlBase, payload);
      return data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
export const updateAddress = createAsyncThunk(
  `${scope}/update`,
  async (payload: { id: number, payload: Partial<IAddress>}, { rejectWithValue }): Promise<Partial<IAddress>> => {
    if (!payload.id) throw Error('id not defined');
    try {
      const { data } = await API.patch<Partial<IAddress>>(`${apiUrlBase}${payload.id}/`, payload.payload);
      return data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
export const archiveAddress = createAsyncThunk(
  `${scope}/archive`,
  async (payload: { id: number, value: boolean }): Promise<Partial<IAddress>> => {
    if (!payload.id) throw Error('id not defined');
    const { id , value } = payload;
    const { data } = await API.patch<Partial<IAddress>>(`${apiUrlBase}${id}/archive/`, { value });
    return data;
  },
);


export const {
  reducer,
  // actions: {
  // setAddress,
  // resetAddress,
  // },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
    // setAddress: (state, payload) => factorySetAction<IAddress>(state, payload),
    // resetAddress: () => ({ ...initialState }),
  },
  extraReducers: builder => new FactoryListBuilder<IAddress, IInitListState<IAddress>>(builder)
    .buildListAction(getList)
    .buildListSetAction(createAddress)
    .buildListSetAction(archiveAddress)
    .buildListSetAction(updateAddress)
  ,

});

export default reducer;
