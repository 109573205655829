export const SCOPE_META_TAG = 'MetaTag';

export const uk = {
  home: {
    title: 'Агромаркетплейс України - Glendeal - Біржа зернових та інших агрокультур ',
    description: 'Glendeal - агроплатформа,що надає вам ефективні інструменти для торгівлі зерновими, бобовими та іншими агрокультурами, доступ до пропозицій по всьому світу та підтримку на кожному етапі.',
  },
  market: {
    title: 'Ринок для продажу агрокультур - Glendeal',
    description: '',
  },
  farmers: {
    title: 'Платформа для фермерів для продажу та купівлі агрокультур - Glendeal',
    description: 'Платформа для фермерів для продажу та купівлі агрокультур ✓ Легкий доступ ✓ Зручний інтерфейс ✓ Багато пропозицій ✓ Glendeal',
  },
  brokers: {
    title: 'Платформа для посередників та брокерів для продажу та купівлі агрокультур - Glendeal',
    description: 'Платформа для посередників та брокерів для продажу та купівлі агрокультур ✓ Легкий доступ ✓ Зручний інтерфейс ✓ Багато пропозицій ✓ Glendeal',
  },
  buyers: {
    title: 'Платформа для продажу та купівлі агрокультур - Glendeal',
    description: 'Платформа для продажу та купівлі агрокультур ✓ Легкий доступ ✓ Зручний інтерфейс ✓ Багато пропозицій ✓ Glendeal',
  },
  contacts: {
    title: 'Контактні дані платформи Glendeal',
    description: 'Наша команда завжди готова допомогти вам і відповісти на усі запитання щодо платформи та послуг',
  },
  about: {
    title: 'Про нас',
    description: 'Гленділ - український маркетплейс, який став результатом накопиченого 15-річного досвіду роботи на фізичному ринку трейдингу, логістики, фермерства та брокерства.',
  },
  categories: {
    title: '[name]: Купити та продати гуртом - Glendeal',
    description: '[name] - Купити та продати: Оголошення з купівлі та продажу по всій Україні та за кордоном ✓ Широкий асортимент ✓ Вигідні ціни',
  },
  cultures: {
    title: '[name]: Купити та продати гуртом - Glendeal',
    description: '[name] - Купити та продати: Оголошення з купівлі та продажу по всій Україні та за кордоном ✓ Широкий асортимент ✓ Вигідні ціни',
  },
};

export const en: typeof uk = {
  home: {
    title: 'Agricultural marketplace of Ukraine - Glendeal - Exchange of grain and other agricultural crops',
    description: 'Glendeal is an agricultural platform that provides you with efficient tools for trading grains, pulses and other crops, access to offers around the world and support at every stage',
  },
  market: {
    title: 'Marketplace for the sale of agricultural crops - Glendeal',
    description: '',
  },
  farmers: {
    title: 'A platform for farmers to sell and buy crops - Glendeal',
    description: 'A platform for farmers to sell and buy crops ✓ Easy access ✓ User-friendly interface ✓ Many offers ✓ Glendeal',
  },
  brokers: {
    title: 'A platform for intermediaries and brokers to sell and buy crops - Glendeal',
    description: 'A platform for intermediaries and brokers to sell and buy crops ✓ Easy access ✓ User-friendly interface ✓ Many offers ✓ Glendeal',
  },
  buyers: {
    title: 'The platform for the sale and purchase of crops - Glendeal',
    description: 'A platform for selling and buying crops ✓ Easy access ✓ User-friendly interface ✓ Many offers ✓ Glendeal',
  },
  contacts: {
    title: 'Contact details of the Glendeal platform',
    description: 'Our team is always ready to help you and answer all your questions about the platform and services',
  },
  about: {
    title: 'About us',
    description: 'Glendeal is a Ukrainian marketplace that is the result of 15 years of experience in the physical market of trading, logistics, farming and brokerage',
  },
  categories: {
    title: '[name]: Buy and sell in bulk - Glendeal',
    description: '[name] - Buy and sell: Classifieds for buying and selling all over Ukraine and abroad ✓ Wide range of products ✓ Favorable prices',
  },
  cultures: {
    title: '[name]: Buy and sell wholesale - Glendeal',
    description: '[name] - Buy and Sell: Classifieds for buying and selling all over Ukraine and abroad ✓ Wide range ✓ Favorable prices',
  },
};

export const ru: typeof uk = {
  home: {
    title: 'Агромаркетплейс Украины - Glendeal - Биржа зерновых и других агрокультур',
    description: 'Glendeal - агроплатформа, предоставляющая вам эффективные инструменты для торговли зерновыми, бобовыми и другими агрокультурами, доступ к предложениям по всему миру и поддержку на каждом этапе',
  },
  market: {
    title: 'Рынок для продажи агрокультур - Glendeal',
    description: '',
  },
  farmers: {
    title: 'Платформа для фермеров для продажи и покупки агрокультур - Glendeal',
    description: 'Платформа для фермеров для продажи и покупки агрокультур ✓ Легкий доступ ✓ Удобный интерфейс ✓ Много предложений ✓ Glendeal',
  },
  brokers: {
    title: 'Платформа для посредников и брокеров для продажи и покупки агрокультур - Glendeal',
    description: 'Платформа для посредников и брокеров для продажи и покупки агрокультур ✓ Легкий доступ ✓ Удобный интерфейс ✓ Много предложений ✓ Glendeal',
  },
  buyers: {
    title: 'Платформа для продажи и покупки агрокультур - Glendeal',
    description: 'Платформа для продажи и покупки агрокультур ✓ Легкий доступ ✓ Удобный интерфейс ✓ Много предложений ✓ Glendeal',
  },
  contacts: {
    title: 'Контактные данные платформы Glendeal',
    description: 'Наша команда всегда готова помочь вам и ответить на все вопросы о платформе и услугах',
  },
  about: {
    title: 'О нас',
    description: 'Глендил - украинский маркетплейс, который стал результатом накопленного 15-летнего опыта работы на физическом рынке трейдинга, логистики, фермерства и брокерства.',
  },
  categories: {
    title: '[name]: Купить и продать оптом - Glendeal',
    description: '[name] - Купить и продать: Объявления по купле и продаже по всей Украине и за рубежом ✓ Широкий ассортимент ✓ Выгодные цены',
  },
  cultures: {
    title: '[name]: Купить и продать оптом - Glendeal',
    description: '[name] - Купить и продать: Объявления по купле и продаже по всей Украине и за рубежом ✓ Широкий ассортимент ✓ Выгодные цены',
  },
};
