export const SCOPE_FILTER_DATE = 'filters.FiltersDate';
export const SCOPE_ADDITIONAL_FILTER = 'filters.AdditionalFilter';
export const SCOPE_EMPTY_FILTER_RESULT = 'filters.EmptyFilterResult';

export const uk = {
  AdditionalFilter: {
    public: {
      all: 'Публичність',
      true: 'Публичні',
      false: 'Приватні',
    },
    status: {
      all: 'Статус',
      active: 'Активні',
      completed: 'Успішні',
      closed: 'Закриті',
    },
  },
  CreatedFilter: {
    range: 'Довільний період',
    day_0: 'Завтра',
    day_1: 'Сегодня',
    day_2: 'Вчора',
    week_0: 'Наступний тиждень',
    week_1: 'Поточний тиждень',
    week_2: 'Минулий тиждень',
    month_0: 'Наступною місяць',
    month_1: 'За цей місяць',
    month_2: 'Минулий місяць',
    quarter_1: 'Поточний квартал',
    quarter_2: 'Минулий квартал',
    halfyear_1: 'У цьому півріччі',
    halfyear_2: 'Минуле півріччя',
    year_1: 'Поточний рік',
    year_2: 'Минулий рік',
    remember: 'Запам\'ятати',
    reset: 'Скинути',
    all: 'Весь період',
    placeholder: 'Період створення заявок',
    infoPopup: {
      setDefault: 'Фільтр \'{{name}} \'встановлений за замовчуванням ',
      unsetDefault: 'Фільтр \'{{name}}\' знятий з умовчання ',
    },
    buttons: {
      setDefault: 'Запам\'ятати',
      unSetDefault: 'Скинути',
    },
    tooltips: {
      mainButton: 'Фільтр за датою створення документів',
      setDefault: 'Даний фільтр буде використовуватися за замовчуванням',
      unSetDefault: 'Зняти фільтр за замовчуванням',
    },
  },
  MultiAutocomplete: {
    companies: {
      label: 'Компанія',
      placeholder: 'Назва компанії',
    },
    categories: {
      label: 'Група товару',
      placeholder: 'Категорія',
    },
    basis: {
      label: 'Базис поставки',
      placeholder: 'Базис поставки',
    },
    cultures: {
      label: 'Продукт',
      placeholder: 'Назва продукту',
    },
    port_options: {
      label: 'Призначення',
      placeholder: 'Призначення',
    },
    countries: {
      label: 'Країни',
      placeholder: 'Країни',
    },
    packagings: {
      label: 'Упаковка',
      placeholder: 'Упаковка',
    },
    origins: {
      label: 'Походження',
      placeholder: 'Походження',
    },
  },
  FiltersTypeToggle: {
    type: {
      label: 'Тип заявки',
      all: 'Всі',
      request: 'Покупка',
      offer: 'Продаж',
    },
    status: {
      label: 'Статус',
      all: 'Всі',
      active: 'Активні',
      closed: 'Закриті',
      completed: 'Контракти',
    },
  },
  FiltersDate: {
    label: 'Період',
    placeholder: 'Термін дії',
  },
  CompactToggle: {
    label: 'Компактний режим',
  },
  AutoSubmit: {
    label: 'Авто оновлення',
  },
  EmptyFilterResult: {
    text: 'Результат пошуку порожній',
    tryToChange: 'Спробуйте змінити фільтр або',
    resetFilters: 'скинути його',
  },
};

export const en: typeof uk = {
  AdditionalFilter: {
    public: {
      all: 'Publicity',
      true: 'Public',
      false: 'Private',
    },
    status: {
      all: 'Status',
      active: 'Active',
      completed: 'Success',
      closed: 'Closed',
    },
  },
  CreatedFilter: {
    range: 'Custom period',
    day_0: 'Tomorrow',
    day_1: 'Today',
    day_2: 'Yesterday',
    week_0: 'Next week',
    week_1: 'Current week',
    week_2: 'Last week',
    month_0: 'Next month',
    month_1: 'Current month',
    month_2: 'Last month',
    quarter_1: 'Current quarter',
    quarter_2: 'Last quarter',
    halfyear_1: 'Current half year',
    halfyear_2: 'Past half year',
    year_1: 'This year',
    year_2: 'Last year',
    remember: 'Remember',
    reset: 'Reset',
    all: 'All',
    placeholder: 'Lot creation period',
    infoPopup: {
      setDefault: 'Filter \'{{name}}\' is set by default',
      unsetDefault: 'Filter \'{{name}}\' is unset by default',
    },
    buttons: {
      setDefault: 'Set',
      unSetDefault: 'Reset',
    },
    tooltips: {
      mainButton: 'Filter by document creation date',
      setDefault: 'Set the filter as default',
      unSetDefault: 'Reset default filter',
    },
  },
  MultiAutocomplete: {
    companies: {
      label: 'Company',
      placeholder: 'Company name',
    },
    categories: {
      label: 'Product group',
      placeholder: 'Category',
    },
    basis: {
      label: 'Basis',
      placeholder: 'Basis',
    },
    cultures: {
      label: 'Product',
      placeholder: 'Product name',
    },
    port_options: {
      label: 'Destinations',
      placeholder: 'Destinations',
    },
    countries: {
      label: 'Countries',
      placeholder: 'Countries',
    },
    packagings: {
      label: 'Packaging',
      placeholder: 'Packaging',
    },
    origins: {
      label: 'Origin',
      placeholder: 'Origin',
    },
  },
  FiltersTypeToggle: {
    type: {
      label: 'Type of request',
      all: 'All',
      request: 'Buy',
      offer: 'Sell',
    },
    status: {
      label: 'Status',
      all: 'All',
      active: 'Active',
      closed: 'Closed',
      completed: 'Contracts',
    },
  },
  FiltersDate: {
    label: 'Range',
    placeholder: 'Valid date',
  },
  CompactToggle: {
    label: 'Compact view',
  },
  AutoSubmit: {
    label: 'Auto refresh',
  },
  EmptyFilterResult: {
    text: 'The search result is empty',
    tryToChange: 'Try to change filter or',
    resetFilters: 'reset it',
  },
};


export const ru: typeof uk = {
  AdditionalFilter: {
    public: {
      all: 'Публичность',
      true: 'Публичные',
      false: 'Приватные',
    },
    status: {
      all: 'Статус',
      active: 'Активные',
      completed: 'Успешные',
      closed: 'Закрытые',
    },
  },
  CreatedFilter: {
    range: 'Произвольный период',
    day_0: 'Завтра',
    day_1: 'Сегодня',
    day_2: 'Вчера',
    week_0: 'Следующая неделя',
    week_1: 'Текущая неделя',
    week_2: 'Прошлая неделя',
    month_0: 'Следующей месяц',
    month_1: 'Текущий месяц',
    month_2: 'Прошлый месяц',
    quarter_1: 'Текущий квартал',
    quarter_2: 'Прошлый квартал',
    halfyear_1: 'Текущее полугодие',
    halfyear_2: 'Прошлое полугодие',
    year_1: 'Текущий год',
    year_2: 'Прошлый год',
    remember: 'Запомнить',
    reset: 'Сбросить',
    all: 'Весь период',
    placeholder: 'Период создания заявок',
    infoPopup: {
      setDefault: 'Фильтр \'{{name}}\' установлен по умолчанию',
      unsetDefault: 'Фильтр \'{{name}}\' снят с умолчания',
    },
    buttons: {
      setDefault: 'Запомнить',
      unSetDefault: 'Сбросить',
    },
    tooltips: {
      mainButton: 'Фильтр по дате создания документов',
      setDefault: 'Данный фильтр будет использоваться по умолчанию',
      unSetDefault: 'Снять фильтр по умолчанию',
    },
  },
  MultiAutocomplete: {
    companies: {
      label: 'Компания',
      placeholder: 'Название компании',
    },
    categories: {
      label: 'Группа товара',
      placeholder: 'Категория',
    },
    basis: {
      label: 'Базис поставки',
      placeholder: 'Базис поставки',
    },
    cultures: {
      label: 'Продукт',
      placeholder: 'Название продукта',
    },
    port_options: {
      label: 'Назначение',
      placeholder: 'Назначение',
    },
    countries: {
      label: 'Страны',
      placeholder: 'Страны',
    },
    packagings: {
      label: 'Упаковка',
      placeholder: 'Упаковка',
    },
    origins: {
      label: 'Происхождение',
      placeholder: 'Происхождение',
    },
  },
  FiltersTypeToggle: {
    type: {
      label: 'Тип заявки',
      all: 'Все',
      request: 'Покупка',
      offer: 'Продажа',
    },
    status: {
      label: 'Статус',
      all: 'Все',
      active: 'Активные',
      closed: 'Закрытые',
      completed: 'Контракты',
    },
  },
  FiltersDate: {
    label: 'Период',
    placeholder: 'Срок действия',
  },
  CompactToggle: {
    label: 'Компактный режим',
  },
  AutoSubmit: {
    label: 'Авто обновление',
  },
  EmptyFilterResult: {
    text: 'Результат поиска пуст',
    tryToChange: 'Попробуйте поменять фильтр или',
    resetFilters: 'сбросить его',
  },
};
