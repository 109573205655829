import { WritableDraft, Draft } from 'immer/dist/types/types-external';
import { ActionReducerMapBuilder, AsyncThunk, PayloadAction} from '@reduxjs/toolkit';

import { IAdditionalApiStatuses, IApiListResponse, IInitListState } from './interfaces';

export class FactoryBuilder<State extends IAdditionalApiStatuses> {

  constructor(public builder: ActionReducerMapBuilder<State>) {}

  buildAction<Returned, ThunkArg>(action: AsyncThunk<Returned, ThunkArg, {}>): this {
    this.builder
      .addCase(action.pending, state => { state.loading = true; })
      .addCase(action.rejected, (state, action) => {
        state.error = action.error?.message || '';
        state.loaded = false;
        state.loading = false;
      })
      .addCase(action.fulfilled, (state, action) => ({
        ...state,
        error: null,
        loading: false,
        loaded: true,
        ...action.payload,
      }));
    return this;
  }
}

export class FactoryListBuilder<T extends { id?: number }, State extends IInitListState<T>> extends FactoryBuilder<State> {
  buildListAction<ThunkArg extends { append?: boolean }>(action: AsyncThunk<IApiListResponse<T> | null, ThunkArg, {}>): this {
    this.builder
      .addCase(action.pending, (state, action) => {
        if (action.meta.arg.append) state.appending = true;
        else state.loading = true;
      })
      .addCase(action.rejected, (state, action) => {
        state.error = action.error?.message || '';
        state.loaded = false;
        state.loading = false;
        state.appending = false;
      })
      .addCase(action.fulfilled, (state, action) => {
        if (action.payload == null) {
          return {
            ...state,
            error: null,
            loaded: true,
            loading: false,
            appending: false,
          };
        }
        return {
          ...state,
          error: null,
          loaded: true,
          loading: false,
          appending: false,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          items: action.meta.arg.append ? [...state.items, ...action.payload.results] : action.payload.results,
          list: buildList(state.list, action.payload.results, action.meta.arg.append),
        };
      });
    return this;
  }
  buildListSetAction<ThunkArg extends { id?: number }>(action: AsyncThunk<Partial<T>, ThunkArg, {}>): this {
    this.builder
      .addCase(action.pending, state => { state.loading = true; })
      .addCase(action.rejected, (state, action) => {
        state.error = action.error?.message || '';
        state.loaded = false;
        state.loading = false;
      })
      .addCase(action.fulfilled, (state, action) => {
        const payload = action.payload;
        const id = (payload.id as number) || action.meta.arg.id;

        if (!id) {
          return state;
        }
        return {
          ...state,
          error: null,
          loading: false,
          loaded: true,
          list: {
            ...state.list,
            [id]: {
              ...state.list[id],
              ...payload,
            },
          },
          items: state.list[id]
            ? state.items.map(item => item.id === id ? { ...item, ...payload } : item)
            : [...state.items, payload],
        };
      });
    return this;
  }
}

export function factorySetAction<T>(state: T & IAdditionalApiStatuses, action: PayloadAction<T>): typeof state {
  return {
    ...state,
    error: null,
    loading: false,
    loaded: true,
    ...action.payload,
  };
}

export function factoryInitState<T>(init: T): typeof init & IAdditionalApiStatuses {
  return {
    ...init,
    error: null,
    loading: true,
    loaded: false,
  };
}

export function factoryInitListState<T>(): IInitListState<T> {
  return {
    items: [],
    list: {},
    count: 0,
    next: null,
    previous: null,
    error: null,
    loading: true,
    loaded: false,
    appending: false,
  };
}


function buildList<T extends { id?: number }>(list: WritableDraft<Record<number, T>>, array: T[], append?: boolean): Record<number, Draft<T> | T> {
  const newList = array.reduce<Record<number, T>>((acc, t) => {
    if (t['id']) {
      acc[t.id] = t;
    }
    return acc;
  }, {});
  return append ? { ...list, ...newList } : newList;
}
