import { ShareTypes } from 'modules/PreviewPage/components/ShareButton/interfaces';

export const SCOPE_SHARED_BUTTONS = 'ShareButton';

const encodeBaseUrl = encodeURIComponent(process.env.REACT_APP_SITE_URL || '');
const nbsp = '\u00A0';

export const uk = {
  types: {
    [ShareTypes.lot]: {
      name: 'Картка Заявки',
      info: 'Картка Заявки',
    },
    [ShareTypes.lots]: {
      name: 'Заявки',
      info: `$t(lot, {"count": {{count}} }), об\`ємом {{volume}}${nbsp}т`,
    },
    [ShareTypes.companyLots]: {
      name: 'Заявки компанії',
      info: 'Компанія "{{company}}"',
      link: 'Заявки компанії "{{company}}"',
      text: 'Заявки компанії "{{company}}"',
    },
    [ShareTypes.filteredCompanyLots]: {
      name: 'Отфільтровані заявки компанії',
      info: 'Компанія "{{company}}"',
      link: 'Отфільтровані заявки компанії "{{company}}"',
      text: 'Заявки компанії "{{company}}"',
    },
    [ShareTypes.similar]: {
      name: 'Подібні Заявки',
      link: 'Подібні заявки',
    },
    [ShareTypes.opposite]: {
      name: 'Зустрічні Заявки',
      link: 'Зустрічні заявки',
    },
  },
  title: 'Поділіться посиланнями',
  sharing_section: 'Поділитися в...',
  buttons: {
    copy: 'Копіювати посилання',
    email: 'Пошта',
    viber: 'Viber',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    device: 'Системні інструменти',
  },
  email_body:`Торгова платформа Glendeal (${encodeBaseUrl})%0A{{encodeText}}%0AПосилання: {{encodeUrl}}`,
  button_tooltips: {
    copy: 'Копіювати посилання',
    email: 'Поділитися через електронну пошту',
    viber: `Поділитися за допомогою $t(${SCOPE_SHARED_BUTTONS}$buttons$viber)`,
    telegram: `Поділитися за допомогою $t(${SCOPE_SHARED_BUTTONS}$buttons$telegram)`,
    whatsapp: `$t(${SCOPE_SHARED_BUTTONS}$buttons$whatsapp) має бути встановлений`,
    facebook: `Поділитися за допомогою $t(${SCOPE_SHARED_BUTTONS}$buttons$facebook)`,
    twitter: `Поділитися за допомогою $t(${SCOPE_SHARED_BUTTONS}$buttons$twitter)`,
    device: 'Поділитися за допомогою системних інструментів',
  },
};

export const en: typeof uk = {
  types: {
    [ShareTypes.lot]: {
      name: 'Lot card',
      info: 'Lot card',
    },
    [ShareTypes.lots]: {
      name: 'Lots',
      info: `$t(lot, {"count": {{count}} }), volume {{volume}}${nbsp}t`,
    },
    [ShareTypes.companyLots]: {
      name: 'Company lots',
      info: 'Company "{{company}}"',
      link: 'Lots of the company "{{company}}"',
      text: 'Lots of the company "{{company}}"',
    },
    [ShareTypes.filteredCompanyLots]: {
      name: 'Filtered company lots',
      info: 'Company "{{company}}"',
      link: 'Filtered lots of the company "{{company}}"',
      text: 'Lots of the company "{{company}}"',
    },
    [ShareTypes.similar]: {
      name: 'Similar Lots',
      link: 'Similar lots',
    },
    [ShareTypes.opposite]: {
      name: 'Opposite Lots',
      link: 'Opposit lots',
    },
  },
  title: 'Share links',
  sharing_section: 'Share...',
  buttons: {
    copy: 'Copy link',
    email: 'Email',
    viber: 'Viber',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    device: 'Your System tools',
  },
  email_body:`Trading platform Гленділ (${encodeBaseUrl})%0A{{encodeText}}%0ALink: {{encodeUrl}}`,
  button_tooltips: {
    copy: 'Copy link',
    email: `Share on $t(${SCOPE_SHARED_BUTTONS}$buttons$email)`,
    viber: `Share on $t(${SCOPE_SHARED_BUTTONS}$buttons$viber)`,
    telegram: `Share on $t(${SCOPE_SHARED_BUTTONS}$buttons$telegram)`,
    whatsapp: `$t(${SCOPE_SHARED_BUTTONS}$buttons$whatsapp) must be installed`,
    facebook: `Share on $t(${SCOPE_SHARED_BUTTONS}$buttons$facebook)`,
    twitter: `Share on $t(${SCOPE_SHARED_BUTTONS}$buttons$twitter)`,
    device: 'Share using system tools',
  },
};

export const ru: typeof uk = {
  types: {
    [ShareTypes.lot]: {
      name: 'Карточка Заявки',
      info: 'Карточка Заявки',
    },
    [ShareTypes.lots]: {
      name: 'Заявки',
      info: `$t(lot, {"count": {{count}} }), объём {{volume}}${nbsp}т`,
    },
    [ShareTypes.companyLots]: {
      name: 'Заявки компании',
      info: 'Компания "{{company}}"',
      link: 'Заявки компании "{{company}}"',
      text: 'Заявки компании "{{company}}"',
    },
    [ShareTypes.filteredCompanyLots]: {
      name: 'Отфильтрованніе заявки компании',
      info: 'Компания "{{company}}"',
      link: 'Отфильтрованніе заявки компании "{{company}}"',
      text: 'Заявки компании "{{company}}"',
    },
    [ShareTypes.similar]: {
      name: 'Похожие Заявки',
      link: 'Подобные заявки',
    },
    [ShareTypes.opposite]: {
      name: 'Встречные Заявки',
      link: 'Встречные заявки',
    },
  },
  title: 'Поделиться ссылками',
  sharing_section: 'Поделиться в...',
  buttons: {
    copy: 'Копировать ссылку',
    email: 'Почта',
    viber: 'Viber',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    device: 'Системные инструменты',
  },
  email_body:`Торговая платформа Glendeal (${encodeBaseUrl})%0A{{encodeText}}%0AСсылка: {{encodeUrl}}`,
  button_tooltips: {
    copy: 'Копировать ссылку',
    email: 'Поделиться через электронную почту',
    viber: `Поделиться с помощью $t(${SCOPE_SHARED_BUTTONS}$buttons$viber)`,
    telegram: `Поделиться с помощью $t(${SCOPE_SHARED_BUTTONS}$buttons$telegram)`,
    whatsapp: `$t(${SCOPE_SHARED_BUTTONS}$buttons$whatsapp) должен быть установлен`,
    facebook: `Поделиться с помощью $t(${SCOPE_SHARED_BUTTONS}$buttons$facebook)`,
    twitter: `Поделиться с помощью $t(${SCOPE_SHARED_BUTTONS}$buttons$twitter)`,
    device: 'Поделиться с помощью системных инструментов',
  },
};
