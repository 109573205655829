import React, { ReactNode } from 'react';
import { Dialog, Box, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Notificate from 'services/notificate.service';
import { useTranslation } from 'react-i18next';
import { AddButton } from '../#CustomComponents';
import { LoadingButton } from 'components/LoadingButton';
import './index.scss';

interface Props {
  show?: boolean;
  text: string;
  children?: ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
}

export const DialogConfirm: React.FC<Props> = ({ show = true, text, children, onConfirm, onClose, loading = false }) => {
  const { t: _t } = useTranslation();

  const hConfirm = () => {
    Notificate.run('confirm');
    onConfirm();
  };
  const hClose = () => {
    Notificate.run('close');
    onClose();
  };

  if (!show) return null;
  return (
    <Dialog
      className="dialog-confirm"
      onClose={onClose}
      open={Boolean(show)}
      transitionDuration={{ enter: 200, exit: 0 }}
    >
      <Box p={4}>
        <IconButton className="close" component="span" size="small" onClick={hClose} >
          <Close/>
        </IconButton>
        <h3>{children || text}</h3>
        <Box mt={3}>
          <Box className="dialog-confirm__btns">
            <AddButton onClick={onClose}>{_t('Отменить')}</AddButton>
            <LoadingButton loading={loading} onClick={hConfirm}>{_t('Подтвердить')}</LoadingButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
