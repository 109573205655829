import i18n from 'i18n';
import { MAP_RU_EN, MAP_UK_EN } from './constants';

// api\apps\sitemaps\services.py
export class Churl {
  item: { name?: string; id?: number; churl?: string };
  churl: string;
  static assign(items: { name: string; id: number; churl: string }[]): void {
    items.forEach(item => {
      item.churl = new Churl(item).calculate();
    });
  }
  constructor(item: { name?: string; id?: number; churl?: string }) {
    this.item = item;
    this.churl = this.normalize(this.item.name);
  }

  calculate(): string {

    if (i18n.language === 'uk') {
      return this.mapNameUkToEn();
    } else if (i18n.language === 'en') {
      return this.trim(this.churl.replace(/(\W|-)+/g, '-'));
    } else if (i18n.language === 'ru') {
      return this.mapNameRuToEn();
    }
    return `${this.item.id}`;
  }

  private mapNameUkToEn(): string {
    this.churl = this.churl.replaceAll('ий', 'yy');
    this.churl = this.churl.replace(/[^-_a-z0-9]/g, (p1) => {
      return MAP_UK_EN[p1] || '';
    });
    this.churl = this.trim(this.churl);
    return this.churl;
  }
  private mapNameRuToEn(): string {
    this.churl = this.churl.replace(/[^-_a-z0-9]/g, (p1) => {
      return MAP_RU_EN[p1] || '';
    });
    this.churl = this.trim(this.churl);
    return this.churl;
  }

  private normalize(str?: string): string {
    if (!str) {
      return '';
    }
    return str
      .replace(/(\s|-)+/g, '-')
      .replace(/_+/g, '_')
      // .replace(/(^[-_]+|[-_]+$)/g, '')
      .toLowerCase();
  }
  private trim(str: string): string {
    return str
      .replace(/(^[-_]+|[-_]+$)/g, '');
  }
}
