export const SCOPE_MULTISELECT = 'multiSelect';

export const uk = {
  countries: {
    all: 'Все',
    title: 'З якими ринками працюєте',
    label: 'Ринок',
    placeholder_c0: 'Фільтр по ринках',
    placeholder_one: 'Вибрано {{count}} ринок',
    placeholder_few: 'Вибрані {{count}} ринки',
    placeholder_many: 'Вибрані {{count}} ринків',
    secondTitle: 'Вибрані ринки',
    actions: {
      addAll: 'Додати всі',
      removeAll: 'Видалити все',
    },
  },
  cultures: {
    all: 'Все',
    title: 'Ваші продуктові групи',
    label: 'Продукт',
    placeholder_c0: 'Фільтр по групам',
    placeholder_one: 'Вибрано {{count}} група',
    placeholder_few: 'Вибрано {{count}} групи',
    placeholder_many: 'Вибрано {{count}} груп',
    secondTitle: 'Вибрані групи',
    actions: {
      addAll: 'Додати всі',
      removeAll: 'Видалити все',
    },
  },
  ports: {
    title: 'Ваші призначення',
    label: 'Призначення',
    placeholder_c0: 'Порт / Місто',
    placeholder_one: 'Вибрано {{count}} призначення',
    placeholder_few: 'Вибрано {{count}} призначення',
    placeholder_many: 'Вибрано {{count}} призначень',
  },
  shipments: {
    title: 'Види відвантажень',
    label: 'Тип',
    placeholder_c0: 'Тип',
    placeholder_one: 'Вибрано {{count}} тип',
    placeholder_few: 'Вибрані {{count}} типи',
    placeholder_many: 'Вибрані {{count}} типів',
  },
  amount_of_workers: {
    label: 'Кількість людей в компанії',
    placeholder: '-',
  },
  annual_turnover: {
    label: 'Річний обіг в дол',
    placeholder: '-',
  },
  is_organic: {
    label: 'Чи працюєте по органіці?',
    placeholder: '-',
  },
};

export const ru = {
  countries: {
    all: 'Все',
    title: 'С какими рынками работаете',
    label: 'Рынок',
    placeholder_c0: 'Фильтр по рынкам',
    placeholder_one: 'Выбран {{count}} рынок',
    placeholder_few: 'Выбрано {{count}} рынка',
    placeholder_many: 'Выбрано {{count}} рынков',
    secondTitle: 'Выбранные рынки',
    actions: {
      addAll: 'Добавить все',
      removeAll: 'Удалить все',
    },
  },
  cultures: {
    all: 'Все',
    title: 'Ваши продуктовые группы',
    label: 'Продукт',
    placeholder_c0: 'Фильтр по группам',
    placeholder_one: 'Выбран {{count}} группа',
    placeholder_few: 'Выбрано {{count}} группы',
    placeholder_many: 'Выбрано {{count}} групп',
    secondTitle: 'Выбранные группы',
    actions: {
      addAll: 'Добавить все',
      removeAll: 'Удалить все',
    },
  },
  ports: {
    title: 'Ваши направления',
    label: 'Назначение',
    placeholder_c0: 'Порт/ Город',
    placeholder_one: 'Выбрано {{count}} направление',
    placeholder_few: 'Выбрано {{count}} направления',
    placeholder_many: 'Выбрано {{count}} направлений',
  },
  shipments: {
    title: 'Виды отгрузок',
    label: 'Тип',
    placeholder_c0: 'Тип',
    placeholder_one: 'Выбран {{count}} тип',
    placeholder_few: 'Выбрано {{count}} типа',
    placeholder_many: 'Выбрано {{count}} типов',
  },
  amount_of_workers: {
    label: 'Количество людей в компании',
    placeholder: '-',
  },
  annual_turnover: {
    label: 'Годовой оброт в долл',
    placeholder: '-',
  },
  is_organic: {
    label: 'Работаете ли по органике?',
    placeholder: '-',
  },
};

export const en = {
  countries: {
    all: 'All',
    title: 'Markets you are working at',
    label: 'Market',
    placeholder_c0: 'Market',
    placeholder_one: 'Selected {{count}} country',
    placeholder_other: 'Selected {{count}} countries',
    secondTitle: 'Choosen countries',
    actions: {
      addAll: 'Add all',
      removeAll: 'Remove all',
    },
  },
  cultures: {
    all: 'All',
    title: 'Your products',
    label: 'Product',
    placeholder_c0: 'Products',
    placeholder_one: 'Selected {{count}} product',
    placeholder_other: 'Selected {{count}} products',
    secondTitle: 'Choosen products',
    actions: {
      addAll: 'Add all',
      removeAll: 'Remove all',
    },
  },
  ports: {
    title: 'Your destinations',
    label: 'Destination',
    placeholder_c0: 'Port / City',
    placeholder_one: 'Selected {{count}} destination',
    placeholder_other: 'Selected {{count}} destinations',
  },
  shipments: {
    title: 'Shipment types',
    label: 'Type',
    placeholder_c0: 'Type',
    placeholder_one: 'Selected {{count}} type',
    placeholder_other: 'Selected {{count}} types',
  },
  amount_of_workers: {
    label: 'Number of people in company',
    placeholder: '-',
  },
  annual_turnover: {
    label: 'Annual turnover in USD',
    placeholder: '-',
  },
  is_organic: {
    label: 'Do you work with Organic?',
    placeholder: '-',
  },
};
