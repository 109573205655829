import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { SetShowContext } from 'hooks/setShow.context';

export function useShowChildren(fn: boolean | (() => boolean)): boolean {
  const setShow = useContext(SetShowContext);

  const show = typeof fn === 'function' ? fn(): fn;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setShow(show); }, [show]);

  return show;
}

export function useShowParent(): [boolean, React.FC<PropsWithChildren<unknown>>] {
  const [show, setShow] = useState(true);

  const SetShowContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => (
    <SetShowContext.Provider value={setShow}>
      {children}
    </SetShowContext.Provider>
  );

  return [show, SetShowContextProvider];
}
