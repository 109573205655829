export const uk = {
  title: '',
  goHome: 'Перейти до Ринку',
  bodyText: 'На жаль дана заявка більш не актуальна або була знята з торгів',
};

export const ru = {
  title: '',
  goHome: 'Перейти к Рынку',
  bodyText: 'К сожалению данная заявка более не актуальна или была снята с торгов',
};

export const en = {
  title: '',
  goHome: 'Go to Market',
  bodyText: 'Unfortunately, this lot is no longer valid or has been withdrawn from the trade',
};
